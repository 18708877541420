// Carousel.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

import './assets/scss/director.scss';
import Box from '@mui/material/Box';
import axios from 'axios';
import Button from '@mui/material/Button';
import { WOW } from 'wowjs';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { GlobalTheme } from '../theme';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    },
        [delay]
    );
}

const Carousel = () => {
    const isMobile = useMediaQuery(GlobalTheme.breakpoints.down('sm')); // Matches "sm" and below
    const isTablet = useMediaQuery(GlobalTheme.breakpoints.between('sm', 'lg')); // Matches only "sm" to "lg" (exclusive)
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg')); // Matches "lg" and above


    const baseBImageUrl = 'https://bankacom.inpro1.fcomet.com/api/backend/uploads';
    const [slidesData, setslidesData] = useState([]);
    const [activeIndex, setActive] = useState(0);
    useEffect(() => {
        const fetchAbout = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_director`);

        Promise.all([fetchAbout])
            .then(([AboutResponse]) => {

                const filteredData = AboutResponse.data.data.filter(about => about.id !== 1).sort((a, b) => a.appear_order - b.appear_order);; // Filter out records where id === 1

                setslidesData(filteredData);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);
    const len = slidesData.length;


    // Navigate to next slide
    const nextSlide = () => {
        setActive((prevActive) => (prevActive + 1) % len);
    };

    // Navigate to previous slide
    const prevSlide = () => {
        setActive((prevActive) => (prevActive - 1 + len) % len);
    };

    //Autoplay
    // useInterval(() => {
    //     setActive((activeIndex + 1) % len);
    // }, 5000);
    const handlers = useSwipeable({
        onSwipedLeft: nextSlide,
        onSwipedRight: prevSlide,
        preventScrollOnSwipe: true,
        trackMouse: true,
    });
    //Return style according to index
    const getStyle = (idx) => {
        const distance_left = idx - activeIndex;
        const distance_right = distance_left > 0 ? distance_left - len : distance_left + len;
        const distance = Math.abs(distance_left) > Math.abs(distance_right) ? distance_right : distance_left;

        const styleObj = {};
        const deviceWidth = window.innerWidth;
        // const centerOffset = isTablet ? deviceWidth * 0.038 : deviceWidth * 0.09;

        // const leftOffset = isTablet ? deviceWidth * 0.04 : deviceWidth * 0.09;   // 4% for desktop, 9% for mobile
        // const rightOffset = isTablet ? deviceWidth * 0.049 : deviceWidth * 0.09;  // 5% for desktop, 9% for mobile
        const leftPadding = isTablet ? deviceWidth * 0.02 : deviceWidth * 0.01;  // Padding for left side (desktop only)
        const rightPadding = isTablet ? deviceWidth * 0.03 : deviceWidth * 0.01; // Extra padding for right side (desktop only)


        const leftOffset = isMobile
            ? deviceWidth * 0.09 // 9% for mobile
            : isTablet
                ? deviceWidth * 0.072 // 6% for tablet
                : deviceWidth * 0.018; // 4% for desktop

        const rightOffset = isMobile
            ? deviceWidth * 0.09 // 9% for mobile
            : isTablet
                ? deviceWidth * 0.072 // 5% for tablet
                : deviceWidth * 0.01; // 3% for desktop

        const centerOffset = isMobile
            ? deviceWidth * 0.12 // 12% for mobile
            : isTablet
                ? deviceWidth * 0.01// 8% for tablet
                : deviceWidth * 0.039; // 4.5% for desktop


        if (distance === 0) {
            // Active slide in the center
            styleObj.left = `${centerOffset}px`;
            styleObj.zIndex = 3;
            styleObj.opacity = 1;
            styleObj.transform = isMobile
                ? "scale(0.9)" // Smaller scale for mobile
                : isTablet
                    ? "scale(0.95)" // Slightly larger for tablets
                    : "scale(1)"; // Default for desktop
            styleObj.marginTop = isMobile
                ? "" // Smaller scale for mobile
                : isTablet
                    ? "45px" // Slightly larger for tablets
                    : "45px"; // Default for desktop
        } else if (distance === -1) {
            // Left slide
            styleObj.left = isMobile
                ? `${-leftOffset}px` // Mobile-specific offset
                : isTablet
                    ? `${-leftOffset * 1.7}px` // Adjusted for tablet
                    : `${-leftOffset - leftPadding}px`; // Desktop with padding
            styleObj.zIndex = 1;
            styleObj.opacity = 1;
            styleObj.transform = isMobile
                ? "scale(0.7)" // Smaller scale for mobile
                : isTablet
                    ? "scale(0.78)" // Slightly larger for tablets
                    : "scale(0.9)"; // Default for desktop
            styleObj.filter = 'grayscale(.9)';
        } else if (distance === 1) {
            // Right slide
            styleObj.right = isMobile
                ? `${-rightOffset}px` // Mobile-specific offset
                : isTablet
                    ? `${-rightOffset * 1.1}px` // Adjusted for tablet
                    : `${-rightOffset - rightPadding}px`; // Desktop with padding
            styleObj.zIndex = 1;
            styleObj.opacity = 1;
            styleObj.transform = isMobile
                ? "scale(0.7)" // Smaller scale for mobile
                : isTablet
                    ? "scale(0.85)" // Slightly larger for tablets
                    : "scale(0.9)"; // Default for desktop
            styleObj.filter = 'grayscale(.9)';
        } else {
            // Hide slides that are farther away
            styleObj.opacity = 0;
            styleObj.transform = "scale(0)";
        }

        // The distance is not less than 2, hide
        if (Math.abs(distance) >= 2) {
            styleObj.opacity = 0;
            styleObj.transform = "scale(0)";
        }
        return styleObj;
    };

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',       // Class to identify elements to animate
            animateClass: 'animate__animated', // Animation class from Animate.css
            offset: 100,           // Distance to start the animation (in px)
            mobile: false,          // Enable animations on mobile
            live: true,            // Recheck for new elements to animate on the page
        });
        wow.init(); // Initialize WOW.js
    }, []);

    return (
        <Box className="carousel" position='relative' {...handlers} >
            <div
                className="card-container"
                data-wow-duration="1s"
                data-wow-delay="1s"
                data-wow-offset='100'
                data-wow-iteration='1'>
                {slidesData.map((director, index) => (
                    <div
                        className="card"
                        key={index}
                        onClick={() => setActive(index)}
                        style={
                            getStyle(index)
                        }
                    >
                        <img
                            src={`${baseBImageUrl}/${isDesktop
                                ? director.image_desktop_profile_image
                                : isTablet
                                    ? director.image_desktop_profile_image
                                    : director.image_mobile_profile_image}`}
                            alt=''
                        />
                        <Box
                            id={`director${index}`}
                            position='absolute'
                            top={0}
                            right={0}
                            display='flex'
                            flexDirection='column'
                            sx={{
                                width: {
                                    xs: 180,
                                    sm: 300,
                                    md: 570
                                },
                                transform: {
                                    xs: 'translate(0, 61px)',
                                    sm: 'translate(-60px, 70px)',
                                    lg: 'translate(-60px, 60px)'
                                }

                            }}
                        >
                            <Typography color='common.white' fontWeight={400} variant='h2'
                                sx={{
                                    fontSize: {
                                        xs: '1.375rem',
                                        md: '2.25rem'
                                    },
                                    pl: {
                                        lg: 6
                                    }
                                }}>
                                {director.heading}
                            </Typography>
                            <Divider sx={{
                                backgroundColor: GlobalTheme.palette.common.white,
                                my: {
                                    xs: 2.5,
                                    md: 4.75
                                },
                                ml: {
                                    lg: 6
                                }
                            }} />
                            <Box display='flex'>
                                <FormatQuoteIcon sx={{
                                    transform: 'rotate(180deg)',
                                    mt: {
                                        xs: -3,
                                        lg: -2.25
                                    },
                                    ml: {
                                        xs: -1.875,
                                        lg: 0
                                    },
                                    opacity: .2,
                                    fontSize: GlobalTheme.typography.h1.fontSize
                                }} />

                                <Typography variant='h5' component='p' color='common.white' fontWeight={100}
                                    sx={{
                                        fontSize: {
                                            xs: '.688rem',
                                            lg: '1.125rem'
                                        },
                                        pr: {
                                            xs: 1.625,
                                            lg: 6
                                        },
                                        // pt: {
                                        //     xs: 3.125,
                                        // },
                                        position: {
                                            xs: 'absolute',
                                            lg: 'relative'
                                        },
                                        left: {
                                            xs: 27,
                                            lg: 0
                                        }
                                    }}
                                >
                                    {director.description}
                                </Typography>
                            </Box>
                        </Box>
                    </div>
                ))}
            </div>

            <Box className="carousel-controls"
                sx={{
                    display: {
                        xs: 'none',
                        sm: 'flex'
                    }
                }}>
                <Button className="prev-btn" onClick={prevSlide} sx={{
                    opacity: 0,
                    '&:hover, &:focus': {
                        opacity: 1
                    }
                }}
                >
                    <ArrowBackIosOutlinedIcon />
                </Button>
                <Button className="next-btn" onClick={nextSlide} sx={{
                    opacity: 0,
                    '&:hover, &:focus': {
                        opacity: 1
                    }
                }}
                >
                    <ArrowForwardIosOutlinedIcon />
                </Button>
            </Box >

        </Box >
    );
};

export default Carousel;