import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import FsLightbox from "fslightbox-react";
import axios from 'axios'; // For API request
import Grid from '@mui/material/Grid2';
import { Parallax, Background } from 'react-parallax';
import useMediaQuery from '@mui/material/useMediaQuery';
import { WOW } from 'wowjs';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import MarqueeScroll from '../components/marque/marque.jsx';
import Footer from '../components/footer/footer.jsx';
import { GlobalTheme } from '../theme';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom'; // For getting query params
import './assets/scss/mantle.scss';

const popModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', lg: '40%' },
    bgcolor: 'background.paper',
    boxShadow: 5,
    p: 0,
};


const Plp = ({ gridHeight }) => {
    const [scrollY, setScrollY] = useState(0);
    const [animate, setAnimate] = useState(false);
    const plHeBaRef = useRef(null);
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));
    const [currentIndex, setCurrentIndex] = useState(0);
    const [images, setImages] = useState([]); // Store products as images array
    const [subcategories, setSubcategories] = useState([]);
    const [toggler, setToggler] = useState(false);
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const baseImageUrl = 'https://banka1964.com/api/cmsapi/uploads';
    const basecorsImageUrl = 'https://banka1964.com/api/cmsapi/uploads';
    const handleOpen = (productIndex) => {
        setCurrentIndex(productIndex);
        setOpen(true);
    };
   
    const decodeBase64 = (encodedId) => {
        return atob(encodedId);
    };
    const {sid} = useParams();
    const subcategory_id = decodeBase64(sid).replace("Banka1964-", "");
    console.log("hi"+subcategory_id);
    const handleClose = () => setOpen(false);
    // Extract subcategory_id from URL query params
    const getSubcategoryIdFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get('subcategory_id');
    };

    // Fetch products from API and update images array
    
    
    useEffect(() => {
        
        if (subcategory_id) {
            axios
                .get(`https://bankacom.inpro1.fcomet.com/cmsapi/products/${subcategory_id}`)
                .then((response) => {
                    const productImages = response.data.filter((product) => product.is_active === 1).map((product) => ({
                        src: product.product_image,
                        alt: product.product_name,
                    }));
                    setImages(productImages); // Update the images array with product data
                })
                .catch((error) => {
                    console.error('Error fetching products:', error);
                });
        }
    }, [subcategory_id,location]);

    // const imageSources = images.map((image) => image.src);


    useEffect(() => {
        fetchSubcategories();
    }, [subcategory_id,location]);

    // Fetch subcategories from API and filter based on condition (id == subcategory_id)
    const fetchSubcategories = async () => {
        //const subcategory_id = getSubcategoryIdFromUrl();
        try {
            const response = await axios.get('https://bankacom.inpro1.fcomet.com/backend/table-data/banka_sub_category');

            // Filter the data where id == subcategory_id
            const filteredData = response.data.data.filter(item => item.id == subcategory_id);
            setSubcategories(filteredData); // Set filtered data
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };

    const handleOpenLightbox = (index) => {
        setCurrentIndex(index);
        setToggler(!toggler);
    };


    const imageSources = images
        .filter((image) => typeof image.src === "string" && image.src.trim() !== "")
        .map((image) => `${basecorsImageUrl}/${image.src}`);
    const getAdjacentIndexes = () => {
        const prevIndex = (currentIndex - 1 + images.length) % images.length;
        const nextIndex = (currentIndex + 1) % images.length;
        return { prevIndex, nextIndex };
    };
    const { prevIndex, nextIndex } = getAdjacentIndexes();

    useEffect(() => {
        if (plHeBaRef.current) {
            plHeBaRef.current.style.height = `${gridHeight}px`; // Set height from prop
        }
    }, [gridHeight]); // Recalculate on gridHeight change


    useEffect(() => {
        // Trigger animation once the component mounts
        const timeout = setTimeout(() => {
            setAnimate(true);
        }, 0); // Delay can be adjusted as needed
        return () => clearTimeout(timeout); // Cleanup
    }, []);

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',       // Class to identify elements to animate
            animateClass: 'animate__animated', // Animation class from Animate.css
            offset: 100,           // Distance to start the animation (in px)
            mobile: false,          // Enable animations on mobile
            live: true,            // Recheck for new elements to animate on the page
        });
        wow.init(); // Initialize WOW.js
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <Grid container spacing={2}>

                <Box id='plHeBa' ref={plHeBaRef} component='section' aria-labelledby='plpBa' width='100%' position='relative'>
                    <Card sx={{ position: 'relative', height: 'inherit', borderRadius: 0 }}>
                        {subcategories.length > 0 && subcategories[0] && (
                            <>
                                <Helmet>
                                    <title>{subcategories[0].sub_category || ' '}: Banka since 1964</title>
                                    <meta name="description" content={subcategories[0].sub_category_seo_description || ' '} />
                                    <meta name="keywords" content={subcategories[0].sub_category_seo_keywords || ' '} />
                                </Helmet>
                                <Card sx={{ position: 'relative', height: 'inherit', borderRadius: 0 }}
                                >
                                    <Parallax
                                        blur={isDesktop ? { min: Math.min(scrollY / 10, -15), max: Math.min(scrollY / 5, 15) } : ''}
                                        strength={-100}
                                        id='mantleImg'
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            display: "flex",

                                        }}
                                        bgImage={`${baseImageUrl}/${isDesktop ? subcategories[0].sub_category_banner : subcategories[0].plp_banner_mobile}`}
                                        title={subcategories[0].sub_category_heading || ' '}
                                    >
                                    </Parallax>
                                </Card>
                            </>
                        )}
                        <Container fixed>
                            {subcategories.length > 0 && subcategories[0] && (
                                <CardContent sx={{
                                    position: 'absolute',
                                    bottom: {
                                        xs: 40,
                                        lg: 88
                                    },
                                }}>
                                    <Typography id='plpBa' marginBottom={4.375} variant="h2" component="h1" color='common.white'
                                        className={animate && isDesktop ? 'animate__animated animate__fadeInLeft animate__delay-1s' : ''}
                                        data-wow-duration="1s"
                                        data-wow-delay="2s"
                                        data-wow-offset='100'
                                        data-wow-iteration='1'
                                        sx={{
                                            "&:after": {
                                                content: '""',
                                                width: '36%',
                                                height: '1px',
                                                display: 'block',
                                                marginTop: 2,
                                                backgroundColor: GlobalTheme.palette.common.white
                                            }
                                        }}>
                                        {subcategories[0].sub_category_heading || 'No Heading'} {/* Accessing the first element safely */}
                                    </Typography>
                                    <Typography color='common.white' variant='body2' fontWeight={200}
                                        className={animate && isDesktop ? 'animate__animated animate__fadeInUp animate__delay-2s' : ''}
                                        data-wow-duration="1s"
                                        data-wow-delay="2s"
                                        data-wow-offset='100'
                                        data-wow-iteration='1'>
                                        {subcategories[0].sub_category_description || 'No Description'} {/* Accessing the first element safely */}
                                    </Typography>
                                </CardContent>
                            )}
                        </Container>
                    </Card>
                </Box>

                <Box component='section' aria-labelledby='sOcBa cCTxtBa iIcTxtBa' width='100%'>
                    <Box
                        // className="wow animate__animated animate__fadeIn"
                        // data-wow-duration="1s"
                        // data-wow-delay="1s"
                        // data-wow-offset='100'
                        // data-wow-iteration='1'
                        sx={{
                            textAlign: 'center',
                            pt: {
                                xs: 6.25,
                                lg: 14
                            },
                            pb: 6.25
                        }}>
                        <Typography id='sOcBa' variant='h2' component='h2' color='primary.main'
                            sx={{
                                position: 'relative',
                                fontSize: {
                                    xs: '1.375rem',
                                    lg: '2.25rem'
                                },

                                "&:before": {
                                    content: '""',
                                    height: 2,
                                    width: '100%',
                                    position: 'absolute',
                                    transform: {
                                        xs: 'translateY(12px)',
                                        lg: 'translateY(22px)'
                                    },
                                    backgroundColor: 'primary.main',
                                    left: 0, right: 0
                                }
                            }}>
                            <Box component='span' position='relative' display='flex' justifyContent='center' sx={{
                                backgroundColor: GlobalTheme.palette.common.white,
                                width: {
                                    xs: '70%',
                                    lg: '25%'
                                },
                                zIndex: 1, margin: '0 auto'
                            }}>
                                {subcategories[0]?.sub_category}
                            </Box>
                        </Typography>
                    </Box>

                    <Container fixed id='p1Ba' sx={{
                        pt: {
                            xs: 4.75,
                            lg: 9.125
                        },
                        pb: 8.125,
                        px: {
                            xs: 2.5,
                            lg: 2
                        }
                    }}>
                        <Grid container spacing={4} justifyContent='center'
                        >
                            {images.map((image, index) => (
                                <Grid item size={{ xs: 6, md: 4, lg: 4 }} key={index}
                                >
                                    <Card
                                        elevation={0}
                                        id={`thBa${index}`}
                                        sx={{ background: GlobalTheme.palette.general.grayLighter, overflow: 'hidden', borderRadius: 2.5 }}
                                        position='relative'

                                    // // className="wow animate__animated animate__flipInY"
                                    // data-wow-duration="3s"
                                    // data-wow-delay="2s"
                                    // data-wow-offset='100'
                                    // data-wow-iteration='1'
                                    >
                                        <CardActionArea
                                            // onClick={() => handleOpenLightbox(index)}
                                            onClick={() => handleOpen(index)}
                                            sx={{
                                                p: {
                                                    xs: 4.75,
                                                    lg: 10.375
                                                },
                                                width: '100%',
                                                height: '100%',
                                                transition: 'transform 2s ease',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    transform: 'scale(2.2)',
                                                },
                                            }}>
                                            <CardMedia
                                                component="img"
                                                image={`${baseImageUrl}/${image.src}`}
                                                alt={image.alt}
                                                loading='lazy'
                                            />
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>



                    </Container>
                </Box>
            </Grid >

            <MarqueeScroll />
            <Footer />
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                        sx: {
                            backdropFilter: 'blur(10px)', // Adjust the blur intensity
                            backgroundColor: 'rgba(GlobalTheme.palette.secondary.lightest, 0.6)', // Optional: Add some darkening effect
                        },
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={popModal}>
                        <Swiper
                            id='pink'
                            slidesPerView={1}
                            spaceBetween={5}
                            keyboard={{ enabled: true }}
                            navigation={false}
                            modules={[Keyboard, Pagination, Navigation]}
                            initialSlide={currentIndex} // Use the current index from the globalProductIndex
                            className='plpSwiper'
                            style={{
                                "& .swiper-slide": {
                                    height: 'auto'
                                }
                            }}
                        >
                            {/* Loop through categories and subcategories exactly like the previous loop */}
                            {images.map((image, index) => (

                                <SwiperSlide id={`pLpBa${index}`} key={index}>
                                    <img
                                        src={`${baseImageUrl}/${image.src}`}
                                        alt={image.alt}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            maxWidth: '100%'
                                        }}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                </Fade>
            </Modal>

            <FsLightbox
                toggler={toggler}
                sources={imageSources}
                slide={currentIndex + 1}
                onClose={() => setToggler(false)}
            />

            {toggler && (
                <Box display="flex" justifyContent="center" mt={2}>
                    <Box mx={1}>
                        <img
                            src={`${baseImageUrl}/${images[prevIndex]?.src}`}
                            alt={images[prevIndex]?.alt}
                            width="50"
                            height="50"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setCurrentIndex(prevIndex)}
                        />
                    </Box>
                    <Box mx={1}>
                        <img
                            src={`${baseImageUrl}/${images[currentIndex]?.src}`}
                            alt={images[currentIndex]?.alt}
                            width="70"
                            height="70"
                            style={{ border: '2px solid blue', cursor: 'pointer' }}
                            onClick={() => setCurrentIndex(currentIndex)}
                        />
                    </Box>
                    <Box mx={1}>
                        <img
                            src={`${baseImageUrl}/${images[nextIndex]?.src}`}
                            alt={images[nextIndex]?.alt}
                            width="50"
                            height="50"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setCurrentIndex(nextIndex)}
                        />
                    </Box>
                </Box>
            )}
        </>
    )
}

export default Plp;