import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Stats = () => {
  const location = useLocation(); // Tracks URL changes

  useEffect(() => {
    // Function to log the page visit
    const logVisit = async () => {
      try {
        const pageUrl = `${location.pathname}${location.search}`; // Full URL with query params
        const referrer = document.referrer || 'Direct'; // Referrer if available

        // Send visit data to the backend
        await axios.post('https://banka1964.com/stats/log-visit', {
          page_url: pageUrl,
          referrer: referrer,
        });

        // console.log('Page visit logged successfully for URL:', pageUrl);
      } catch (error) {
        console.error('Error logging page visit:', error);
      }
    };

    // Log visit whenever the URL changes
    logVisit();
  }, [location.pathname, location.search]); // Trigger effect on path or query changes

  return null; // No visible UI
};

export default Stats;
