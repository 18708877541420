import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Parallax } from 'react-parallax';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';
import CardActionArea from '@mui/material/CardActionArea';
import MarqueeScroll from '../components/marque/marque.jsx';
import Footer from '../components/footer/footer.jsx';
import { GlobalTheme } from '../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { WOW } from 'wowjs';
import HelMet from '../components/helmet.jsx';
const popModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', lg: '40%' },
    bgcolor: 'background.paper',
    boxShadow: 5,
    p: 0,
};

const ValleyPlp = ({ gridHeight }) => {
    const plHeBaRef = useRef(null);
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));
    const [scrollY, setScrollY] = useState(0);
    const [animate, setAnimate] = useState(false);

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const [banner, setBanner] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [products, setProducts] = useState([]);
    const location = useLocation();

    // Global product index counter
    let globalProductIndex = 0;

    const handleOpen = (productIndex) => {
        setCurrentIndex(productIndex);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);
    const baseImageUrl = 'https://bankacom.inpro1.fcomet.com/api/cmsapi/uploads';
    const baseBImageUrl = 'https://bankacom.inpro1.fcomet.com/api/backend/uploads';
    const getCategoryIdFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get('category_id');
    };
    const decodeBase64 = (encodedId) => {
        return atob(encodedId);
    };
    const {cid} = useParams();
    const category_id=decodeBase64(cid).replace("Banka1964-", "");
    // Fetch categories, subcategories, and products
    useEffect(() => {
        const fetchCategories = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_category`);
        const fetchSubcategories = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_sub_category`);
        const fetchProducts = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_product`);
        const fetchBanner = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_valley_banner`);

        Promise.all([fetchCategories, fetchSubcategories, fetchProducts, fetchBanner])
            .then(([categoryResponse, subcategoryResponse, productResponse, bannerResponse]) => {
                setCategories(categoryResponse.data.data);
                setSubcategories(subcategoryResponse.data.data);
                setProducts(productResponse.data.data);
                const filteredData = bannerResponse.data.data.filter(slide => slide.id !== 1); // Filter out records where id === 1

                setBanner(filteredData);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);
    const filteredCategories = category_id
        ? categories.filter(category => category.id === parseInt(category_id))
        : categories;


    useEffect(() => {
        if (plHeBaRef.current) {
            plHeBaRef.current.style.height = `${gridHeight}px`; // Set height from prop
        }
    }, [gridHeight]); // Recalculate on gridHeight change
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    useEffect(() => {
        // Trigger animation once the component mounts
        const timeout = setTimeout(() => {
            setAnimate(true);
        }, 0); // Delay can be adjusted as needed
        return () => clearTimeout(timeout); // Cleanup
    }, []);

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',       // Class to identify elements to animate
            animateClass: 'animate__animated', // Animation class from Animate.css
            offset: 100,           // Distance to start the animation (in px)
            mobile: false,          // Enable animations on mobile
            live: true,            // Recheck for new elements to animate on the page
        });
        wow.init(); // Initialize WOW.js
    }, []);
    return (
        <>
            <Grid container spacing={2}>
                {/* First Category Banner as Page Banner */}

                <Box id='plHeBa' ref={plHeBaRef} component='section' aria-labelledby='plpBa' width='100%' position='relative'>
                    {banner.length > 0 && (
                        <>
                            <HelMet page_name="valley" />
                            <Card sx={{ position: 'relative', height: 'inherit', borderRadius: 0 }}
                                className="wow animate__animated animate__fadeIn"
                                data-wow-duration="1s"
                                data-wow-delay="1s"
                                data-wow-offset='100'
                                data-wow-iteration='1'>
                                <Parallax
                                    blur={isDesktop ? { min: Math.min(scrollY / 10, -15), max: Math.min(scrollY / 5, 15) } : ''}
                                    strength={-100}
                                    id='mantleImg'
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        display: "flex",
                                    }}

                                    bgImage={`${baseBImageUrl}/${isDesktop ? banner[0].image_valley_listing_page_banner : banner[0].image_valley_plp_banner_mobile}`}
                                    title='Popular Product'
                                >
                                </Parallax>
                                <Container fixed>
                                    <CardContent sx={{ position: 'absolute', bottom: 0 }}>
                                        <Typography id='plpBa' gutterBottom variant="h1" component="h1" color='common.white'
                                            className={animate && isDesktop ? 'animate__animated animate__fadeInLeft animate__delay-1s' : ''}
                                            data-wow-duration="1s"
                                            data-wow-delay="2s"
                                            data-wow-offset='100'
                                            data-wow-iteration='1'
                                        >
                                            {banner[0].valley_page_banner_heading}
                                        </Typography>
                                        <Typography variant="body2" fontWeight={200} color='common.white'
                                            className={animate && isDesktop ? 'animate__animated animate__fadeInUp animate__delay-2s' : ''}
                                            data-wow-duration="1s"
                                            data-wow-delay="2s"
                                            data-wow-offset='100'
                                            data-wow-iteration='1'
                                        >
                                            {banner[0].valley_page_banner_description}
                                        </Typography>
                                    </CardContent>
                                </Container>
                            </Card>
                        </>
                    )}
                </Box>

                {/* Loop through categories and their subcategories to list products */}
                <Container maxWidth='false' disableGutters>
                    {filteredCategories.map((category, categoryIndex) => (
                        <Box key={categoryIndex} component='section' aria-labelledby='category' width='100%' marginTop={1.25}>
                            {/* Print Category Name */}
                            <Container maxWidth='false' disableGutters sx={{ justifyContent: 'center', textAlign: 'center', pt: 15.87, pb: 6.625 }}>
                                <Typography component='h2' variant='h2' color='primary' position='relative'
                                    sx={{
                                        position: 'relative',
                                        fontSize: {
                                            xs: '1.375rem',
                                            lg: '2.25rem'
                                        },
                                        "&:before": {
                                            content: '""',
                                            height: 2,
                                            width: '100%',
                                            position: 'absolute',
                                            transform: {
                                                xs: 'translateY(12px)',
                                                lg: 'translateY(22px)'
                                            },
                                            backgroundColor: 'primary.main',
                                            left: 0, right: 0
                                        }
                                    }}>
                                    <Box component='span' position='relative' display='flex' justifyContent='center' sx={{
                                        backgroundColor: GlobalTheme.palette.common.white,
                                        width: {
                                            xs: '70%',
                                            lg: '25%'
                                        },
                                        zIndex: 1, margin: '0 auto'
                                    }}>
                                        {category.category} Collection
                                    </Box>
                                </Typography>
                            </Container>

                            {/* Loop through subcategories of the category */}
                            <Container fixed>
                                <Grid container spacing={4} justifyContent='center'>
                                    {/* Filter subcategories that belong to the current category */}
                                    {subcategories.filter(subcategory => subcategory.category_id === category.id)
                                        .map(subcategory => (
                                            // Filter and display products under each subcategory
                                            // && product.show_in_popular === 1
                                            products.filter(product => product.sub_category_id === subcategory.id)
                                                .map((product) => {
                                                    const productIndex = globalProductIndex++; // Increment global index
                                                    return (
                                                        <Grid item size={{ xs: 12, lg: 4 }} key={productIndex}>
                                                            <Card
                                                                elevation={0}
                                                                id={`${productIndex}`}
                                                                sx={{ background: GlobalTheme.palette.general.grayLighter, overflow: 'hidden', borderRadius: 2.5 }}
                                                                position='relative'
                                                            >
                                                                <CardActionArea onClick={() => handleOpen(productIndex)}
                                                                    sx={{
                                                                        p: 10.375,
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        transition: 'transform 2s ease',
                                                                        cursor: 'pointer',
                                                                        '&:hover': {
                                                                            transform: 'scale(2.2)',
                                                                        },
                                                                    }}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        loading='lazy'
                                                                        image={`${baseImageUrl}/${product.product_image}`}
                                                                        alt={product.product_name}
                                                                    />
                                                                </CardActionArea>
                                                            </Card>
                                                        </Grid>
                                                    );
                                                })
                                        ))}
                                </Grid>
                            </Container>
                        </Box>
                    ))}
                </Container>
            </Grid>

            <MarqueeScroll />
            <Footer />

            {/* Lightbox Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                        sx: {
                            backdropFilter: 'blur(10px)', // Adjust the blur intensity
                            backgroundColor: 'rgba(GlobalTheme.palette.secondary.lightest, 0.6)', // Optional: Add some darkening effect
                        },
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={popModal}>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            keyboard={{ enabled: true }}
                            navigation={false}
                            modules={[Keyboard, Pagination, Navigation]}
                            initialSlide={currentIndex} // Use the current index from the globalProductIndex
                            className='plpSwiper'
                            style={{
                                "& .swiper-slide": {
                                    height: 'auto'
                                }
                            }}
                        >
                            {/* Loop through categories and subcategories exactly like the previous loop */}
                            {filteredCategories.map((category) => (
                                subcategories
                                    .filter((subcategory) => subcategory.category_id === category.id)
                                    .map((subcategory) => (
                                        products
                                            .filter((product) => product.sub_category_id === subcategory.id)
                                            .map((product) => {
                                                const productIndex = globalProductIndex++;
                                                return (
                                                    <SwiperSlide id={`${productIndex}`} key={product.id}>
                                                        <img
                                                            src={`${baseImageUrl}/${product.product_image}`}
                                                            alt={product.product_name}
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                    </SwiperSlide>
                                                );
                                            })
                                    ))
                            ))}
                        </Swiper>
                    </Box>
                </Fade>
            </Modal>

        </>
    );
};

export default ValleyPlp;
