import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import { styled } from '@mui/system';
import { WOW } from 'wowjs';

import CountUp from 'react-countup';
import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import MarqueeScroll from '../components/marque/marque.jsx';
import Footer from '../components/footer/footer.jsx';
import { GlobalTheme } from '../theme';

import { useLocation } from 'react-router-dom';
import StarLogo from './assets/images/star.svg';
import BankaLogo from './assets/images/Bankalogo.svg';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

import './assets/scss/about.scss';
import './assets/scss/counter.scss';

import CarouselDirector from './director.jsx';
// import CarouselDirectorMobile from './directorTobile.jsx';
import BankaStarGlowLogo from './assets/images/starGlow.svg';
import mOurVisionLogo from './assets/images/mOurVision.svg';
import HelMet from '../components/helmet.jsx';

const AboutUS = () => {
    // const [animate, setAnimate] = useState(false);
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('sm'));
    const [about, setAbout] = useState([]);
    const location = useLocation();
    const baseImageUrl = 'https://bankacom.inpro1.fcomet.com/api/cmsapi/uploads';
    const baseBImageUrl = 'https://bankacom.inpro1.fcomet.com/api/backend/uploads';
    const [isExpandedDaddy, setIsExpandedDaddy] = useState(false);
    const [isExpandedSon, setIsExpandedSon] = useState(false);
    const containerRef = useRef(null);
    const daddyRef = useRef(null);
    const sonRef = useRef(null);
    const targetElement = document.getElementById("stLoBa");
    const [isVisible, setIsVisible] = useState(false);  // State to control visibility
    const sonButtonRef = useRef(null);
    const daddyButtonRef = useRef(null);
    // Function to handle click on AnimatedButton
    const handleClickOutside = (event) => {
        const isClickInsideContainer = containerRef.current?.contains(event.target);
        const isClickOnSonButton = sonButtonRef.current?.contains(event.target);
        const isClickOnDaddyButton = daddyButtonRef.current?.contains(event.target);

        if (!isClickInsideContainer) {
            // Close "Daddy" if clicking outside its button
            if (isExpandedDaddy && !isClickOnSonButton) {
                setIsExpandedDaddy(false);
                setIsExpandedSon(false);
            }

            // Close "Son" if clicking outside its button
            if (isExpandedSon && !isClickOnDaddyButton) {
                setIsExpandedSon(false);
                setIsExpandedDaddy(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isExpandedDaddy, isExpandedSon]);

    const handleDaddyExpandClick = () => {
        setIsExpandedSon(false);
        setIsExpandedDaddy(true);

    };
    const handleDaddyClose = () => {

        setIsExpandedDaddy(false);
        setIsExpandedSon(false);

    };
    const handleSonClose = () => {

        setIsExpandedSon(false);
        setIsExpandedDaddy(false);

    };
    const handleDaddySonClose = () => {
        setIsExpandedDaddy(false);
        setIsExpandedSon(false);

    };
    const handleSonExpandClick = () => {
        setIsExpandedDaddy(false);
        setIsExpandedSon(true);

    };

    const { ref, inView } = useInView({
        triggerOnce: true, // Animates only once when scrolled into view
        threshold: 0.5,    // Trigger when 30% of the component is visible
    });

    const counters = [
        { start: 0, end: 60, suffix: "+", text: "golden years of \ninnovation" },
        { start: 0, end: 800, suffix: "+", text: "reputed clients" },
        { start: 0, end: 7, suffix: "+", text: "states served" },
    ];

    const AnimatedButton = styled(Button)({
        position: 'relative',
        overflow: 'hidden',
        paddingLeft: 0,
        paddingTop: 0, paddingBottom: 0,
        paddingRight: '2rem', // Adds space for the arrow to move
        transition: 'color 0.3s ease',

        // Text and Arrow Animation
        '& .MuiButton-label': {
            display: 'flex',
            alignItems: 'center',
            transition: 'transform 0.3s ease',
        },

        // Arrow Animation
        '& .arrow-icon': {
            marginLeft: '0.5rem',
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            opacity: 1,
        },

        // Underline Animation
        '&::after': {
            content: '""',
            position: 'absolute',
            left: '-10px',
            bottom: 0,
            height: '2px',
            width: 0,
            transform: 'translateX(24px)',
            backgroundColor: 'currentColor',
            transition: 'width 0.3s ease',
        },

        // Hover State
        '&:hover': {
            backgroundColor: 'transparent',

            '& .MuiButton-label': {
                transform: 'translateX(15px)', // Moves text and arrow together to the right
            },
            '& .arrow-icon': {
                transform: 'translateX(8px)', // Moves the arrow to the right
                opacity: 0, // Fades out the arrow
            },
            '&::after': {
                width: '55%', // Expands the underline
            },
        },

        // Hover-out State for Reverse Animation
        '&:not(:hover)': {
            '& .MuiButton-label': {
                transform: 'translateX(0)',
            },
            '& .arrow-icon': {
                transform: 'translateX(0)',
                opacity: 1,
            },
            '&::after': {
                width: 0,
            },
        },
    });


    // Fetch categories, subcategories, and products
    useEffect(() => {
        const fetchAbout = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_about_us`);

        Promise.all([fetchAbout])
            .then(([AboutResponse]) => {

                const filteredData = AboutResponse.data.data.filter(about => about.id !== 1); // Filter out records where id === 1

                setAbout(filteredData);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // useEffect(() => {
    //     const handleClickOutsideDaddyEvent = (event) => {
    //         // const container = document.getElementById('toCoBa');
    //         if (containerRef.current && !containerRef.current.contains(event.target)) {
    //             setIsExpandedDaddy(false);
    //         }
    //     };

    //     if (isExpandedDaddy || isExpandedSon) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [isExpandedDaddy, isExpandedSon]);



    // useEffect(() => {
    //     // Trigger animation once the component mounts
    //     const timeout = setTimeout(() => {
    //         // setAnimate(true);
    //     }, 0); // Delay can be adjusted as needed
    //     return () => clearTimeout(timeout); // Cleanup
    // }, []);

    useEffect(() => {
        // Set timeout to change visibility after 5 seconds
        const timer = setTimeout(() => {
            setIsVisible(true);
        });  // 5000ms = 5 seconds

        // Cleanup timeout on unmount
        return () => clearTimeout(timer);
    }, []);  // Empty dependency array ensures this runs only once after initial render


    // useEffect(() => {
    //     const wow = new WOW({
    //         boxClass: 'wow',       // Class to identify elements to animate
    //         animateClass: 'animate__animated', // Animation class from Animate.css
    //         offset: 100,           // Distance to start the animation (in px)
    //         mobile: false,          // Enable animations on mobile
    //         live: true,            // Recheck for new elements to animate on the page
    //     });
    //     wow.init(); // Initialize WOW.js
    // }, []);

    return (
        <>
            <Container disableGutters maxWidth={false} id='plMaBa' component='section' aria-labelledby='plpBa' position='relative' >
                {about.length > 0 && (
                    <>
                        <HelMet page_name="about" />
                        {about.map((item, index) => (
                            <>
                                <Container key={index} fixed sx={{
                                    textAlign: 'center', mt: 9, mb: {
                                        xs: 8.125,
                                        sm: 18.125
                                    }, display: 'flex', justifyContent: 'center', flexWrap: 'wrap'
                                }}
                                // className={animate && isDesktop ? 'wow animate__animated animate__fadeIn' : ''}
                                // data-wow-duration="1s"
                                // data-wow-delay="1s"
                                // data-wow-offset='100'
                                // data-wow-iteration='1'
                                >
                                    <Typography
                                        key={index}
                                        variant="h1"
                                        component='h1'
                                        fontWeight={500}
                                        // className={animate && isDesktop ? 'animate__animated animate__fadeInUp animate__delay-1s' : ''}
                                        sx={{
                                            fontSize: {
                                                xs: '1.375rem',
                                                sm: '2.25rem'
                                            },
                                            background: (theme) =>
                                                `linear-gradient(315deg, ${theme.palette.primary.main} 62%, ${theme.palette.primary.light} 100%)`,
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            textAlign: 'justify',
                                            backgroundSize: '200% 200%',
                                            animation: 'gradientMove 10s ease infinite',
                                            '@keyframes gradientMove': {
                                                '0%': {
                                                    backgroundPosition: 'top left',
                                                },
                                                '100%': {
                                                    backgroundPosition: 'bottom right',
                                                },
                                            },
                                        }}>
                                        {item.title || 'No Title'}
                                    </Typography>
                                    <Typography
                                        variant='h4'
                                        component='p'
                                        fontWeight={300}
                                        color={GlobalTheme.palette.secondary.light}
                                        sx={{
                                            fontSize: {
                                                xs: '.75rem',
                                                sm: '1.375rem'
                                            },
                                            my: 6.25,
                                            lineHeight: 1.7,
                                            px: {
                                                xs: 4,
                                                sm: 7,
                                                md: 17
                                            }
                                        }}
                                    >{item.description || 'No Description'}</Typography>
                                </Container>

                                <Box position='relative' display='flex' flexWrap='wrap' justifyContent='center' ref={containerRef}>

                                    {/* Daddy Start */}
                                    <Grid container id='toCoBa' position='relative'>
                                        <Box id='toCoTxBa'
                                            display='flex'
                                            justifyContent='center'
                                            flexDirection='column'
                                            // className={animate && isDesktop ? 'wow animate__animated animate__fadeInLeft' : ''}
                                            // data-wow-duration="1s"
                                            // data-wow-delay="1s"
                                            // data-wow-offset='100'
                                            // data-wow-iteration='1'
                                            sx={{
                                                pt: 5.12,
                                                pb: 3.375,
                                                px: {
                                                    xs: 2,
                                                    sm: 4,
                                                    lg: 12.5
                                                },
                                                pr: {
                                                    xs: isExpandedDaddy ? 3 : 2,
                                                },
                                                width:
                                                    isExpandedDaddy ? {
                                                        xs: '97%',
                                                        sm: '70%',
                                                        md: '63%',
                                                        lg: '73%'
                                                    } : {
                                                        xs: '80%',
                                                        sm: '50%'
                                                    },
                                                maxHeight: isExpandedDaddy ? 'none' : { xs: 227, sm: 370 },
                                                overflow: 'hidden',
                                                backgroundColor: GlobalTheme.palette.general.grayLighter,
                                                borderRadius: '0 200px 200px 0',
                                                transition: "width 1.5s ease-in-out",
                                            }}>
                                            <Typography component='h2' color='general.grayLightest'
                                            >
                                                <Typography component='span' fontWeight={400} variant='h3' onClick={handleDaddyClose}
                                                    sx={{
                                                        width: '100%',
                                                        display: 'block',
                                                        fontSize: {
                                                            xs: '.75rem',
                                                            sm: '1.5rem',
                                                            lg: '2.25rem'
                                                        }
                                                    }}>
                                                    {item.small_heading_left || 'No Small Heading Left'}
                                                </Typography>
                                                <Typography variant="h2" component={'span'} color='secondary.lightest' fontWeight={500} onClick={handleDaddyClose}
                                                    sx={{
                                                        fontSize: {
                                                            xs: '.75rem',
                                                            sm: '1.5rem',
                                                            lg: '2.25rem'
                                                        }
                                                    }}>{item.big_hedaing_left || 'No Big Heading Left'}</Typography>
                                            </Typography>

                                            <Box id='abABa' overflow='hidden' marginTop={{
                                                xs: 1.875,
                                                xl: 5.625
                                            }}>
                                                <Typography
                                                    color='secondary.light'
                                                    component='p'
                                                    variant='subtitle2'
                                                    fontWeight={200}
                                                    paddingRight={{
                                                        xs: 3,
                                                        sm: isExpandedDaddy ? 15 : 4.25,
                                                        md: isExpandedDaddy ? 15 : 0,
                                                        lg: 24.25
                                                    }}
                                                    lineHeight={1.4}
                                                    marginBottom={2}
                                                >{item.description_left || 'No Description Left'}</Typography>
                                                <Typography color='secondary.light' component='p' variant='subtitle2'
                                                    fontWeight={200}
                                                    paddingRight={{
                                                        xs: 3,
                                                        sm: 24.25,
                                                    }}
                                                    lineHeight={1.4}
                                                    display='flex'
                                                    flexWrap='wrap'
                                                    sx={{
                                                        opacity: isExpandedDaddy ? 1 : 0,
                                                        transition: "opacity 1.5s ease-in-out",
                                                        display: isExpandedDaddy ? 'flex' : 'none'
                                                    }}
                                                >
                                                    <Typography component='span' variant='subtitle2' fontWeight={500} marginBottom={.5}>{item.description_left_read_more_heading || 'No Description Left'}</Typography>
                                                    <Box component='span'>{item.description_left_read_more || 'No Description Left'}</Box>
                                                </Typography>
                                            </Box>


                                            <Box
                                                //  marginTop={{
                                                //     xs: 1.875,
                                                //     sm: 5.37
                                                // }}
                                                sx={{
                                                    transform: isExpandedDaddy ? 'translateY(20px)' : '',
                                                    transition: "all 1.5s ease-in-out",
                                                    opacity: isExpandedDaddy ? 0 : 1,
                                                }}>
                                                <AnimatedButton id='toCoBtBa' endIcon={<ArrowForwardOutlinedIcon className="arrow-icon" />} onClick={handleDaddyExpandClick} ref={daddyButtonRef}>
                                                    <Typography component='span' className='MuiButton-label'
                                                        sx={{
                                                            fontSize: {
                                                                xs: '.5rem',
                                                                sm: '.875rem'
                                                            }
                                                        }}>Read More</Typography>
                                                </AnimatedButton>
                                            </Box>

                                        </Box>

                                        <Box id='toCoImBa'
                                            display='flex'
                                            justifyContent='start'
                                            alignItems='center'
                                            sx={{
                                                width: {
                                                    xs: isExpandedDaddy ? '13%' : '20%',
                                                    sm: isExpandedDaddy ? '50%' : '50%',
                                                    md: isExpandedDaddy ? '20%' : '50%',
                                                },
                                                transition: "all 1.5s ease-in-out",
                                                transform: `
                                                ${isExpandedSon ? "scale(0.9)" : "scale(1)"} 
                                                ${isDesktop && isExpandedSon ? "translateX(30px)" : "translateX(0)"}
                                                `,
                                                position: {
                                                    xs: 'absolute',
                                                    md: 'revert'
                                                },
                                                right: {
                                                    xs: isExpandedDaddy ? 0 : 20,
                                                },
                                                height: '100%',
                                                top: 0,
                                                bottom: 0,
                                                zIndex: 2
                                            }}
                                        // className={animate && isDesktop ? 'wow animate__animated animate__fadeInRight' : ''}
                                        // data-wow-duration="1s"
                                        // data-wow-delay="1s"
                                        // data-wow-offset='100'
                                        // data-wow-iteration='1'
                                        >
                                            {item.image_round2 && (
                                                <img
                                                    src={`${baseBImageUrl}/${item.image_round1}`}
                                                    alt="dady"
                                                    style={{
                                                        width: !isDesktop ? "100%" : "auto",
                                                        filter: isExpandedSon ? "grayscale(100%) opacity(0.7)" : "none",
                                                        transform: {
                                                            sm: isExpandedSon ? "translateX(50px)" : ''
                                                        },
                                                        transition: "transform 1.5s",
                                                    }}
                                                    onClick={handleDaddyClose}
                                                />
                                            )}
                                        </Box>
                                    </Grid >
                                    {/* Daddy End */}

                                    {/* middle logo start */}
                                    <Box id='stLoBa' display='flex' flexWrap='wrap' justifyContent='center' position='absolute' top={0} bottom={0} height='100%' zIndex={1}
                                        sx={{
                                            // opacity: (!isDesktop && (isExpandedDaddy || isExpandedSon) ? 0 : ''),
                                            // opacity: isDesktop ? (isVisible ? 1 : 0) : !isDesktop && (isExpandedDaddy || isExpandedSon) ? 0 : '',
                                            // transform: isDesktop ? (!isExpandedDaddy && !isExpandedSon ? 'scale(1)' : 'scale(0.5)') : (isExpandedDaddy || isExpandedSon ? 'scale(6)' : 'scale(1)'),

                                            opacity: isExpandedDaddy || isExpandedSon ? 0 : 1,
                                            transform: isExpandedDaddy || isExpandedSon ? 'scale(6)' : 'scale(1)',

                                            width: 'auto',
                                            visibility: isVisible ? 'visible' : 'hidden',
                                            transition: "all .5s ease-in",
                                            zIndex: 1
                                        }}
                                    >
                                        <img src={BankaStarGlowLogo} alt=''
                                            style={{
                                                maxWidth: !isDesktop ? "100%" : "auto",

                                            }}
                                            className='star' onClick={handleDaddySonClose} />
                                    </Box>
                                    {/* middle logo end */}

                                    {/* Son Start*/}
                                    <Grid container id='boCoBa' sx={{
                                        position: 'relative',
                                        justifyContent: 'flex-end'
                                    }}>

                                        <Box id='boCoImBa' display='flex' justifyContent='end' alignItems='center'
                                            // className={animate && isDesktop ? 'wow animate__animated animate__fadeInLeft' : ''}
                                            // data-wow-duration="1s"
                                            // data-wow-delay="1s"
                                            // data-wow-offset='100'
                                            // data-wow-iteration='1'
                                            sx={{
                                                width: {
                                                    xs: isExpandedSon ? '13%' : '20%',
                                                    sm: isExpandedSon ? '50%' : '50%',
                                                    md: isExpandedSon ? '20%' : '50%',
                                                },
                                                position: {
                                                    xs: 'absolute',
                                                    md: 'revert'
                                                },
                                                left: {
                                                    xs: isExpandedSon ? 0 : 20,

                                                },
                                                height: '100%',
                                                top: 0,
                                                bottom: 0,
                                                transform: `
                                                ${isExpandedDaddy ? "scale(0.9)" : "scale(1)"} 
                                                ${isDesktop && isExpandedDaddy ? "translateX(30px)" : "translateX(0)"} `,
                                                zIndex: 1,
                                                transition: "all 1.5s ease-in-out",
                                            }}
                                        >
                                            {item.image_round1 && (
                                                <img
                                                    src={`${baseBImageUrl}/${item.image_round2}`}
                                                    alt="son"
                                                    style={{
                                                        width: !isDesktop ? "100%" : "auto",
                                                        filter: isExpandedDaddy ? "grayscale(100%) opacity(0.7)" : "none",
                                                        transform: {
                                                            sm: isExpandedDaddy ? "translateX(-50px)" : ''
                                                        },
                                                        transition: "transform 1.5s",
                                                    }}
                                                    onClick={handleSonClose}
                                                />
                                            )}
                                        </Box>

                                        <Box id='boCoTxBa' display='flex' justifyContent='end' flexDirection='column' textAlign='right'
                                            // className={animate && isDesktop ? 'wow animate__animated animate__fadeInRight' : ''}
                                            // data-wow-duration="1s"
                                            // data-wow-delay="1s"
                                            // data-wow-offset='100'
                                            // data-wow-iteration='1'
                                            sx={{
                                                pt: 4.625,
                                                pb: 4.125,

                                                px: {
                                                    xs: 2,
                                                    sm: 4,
                                                    lg: 12.5
                                                },
                                                width: {
                                                    xs: isExpandedSon ? '97%' : '80%',
                                                    sm: isExpandedSon ? '80%' : '50%',
                                                    md: isExpandedSon ? '63%' : '50%',
                                                    xl: isExpandedSon ? '70%' : '50%',

                                                },

                                                maxHeight: isExpandedSon ? 'none' : { xs: 231, sm: 370 },
                                                overflow: 'hidden',
                                                backgroundColor: GlobalTheme.palette.general.grayLighter,
                                                borderRadius: '200px 0  0 200px',
                                                transition: "width 1.5s ease-in-out",
                                            }}>
                                            <Typography component='h2' variant="h3" color='general.grayLightest' marginBottom={{
                                                xs: 1.875,
                                                xl: 5.625
                                            }} >
                                                <Typography component='span' onClick={handleSonClose} sx={{
                                                    width: '100%', display: 'block',
                                                    fontSize: {
                                                        xs: '.75rem',
                                                        sm: '1.5rem',
                                                        lg: '2.25rem'
                                                    }
                                                }}
                                                    fontWeight={500}
                                                    variant='h3'>
                                                    {item.small_heading_right || ''}

                                                </Typography>
                                                <Typography variant="h2" component={'span'} color='secondary.lightest' fontWeight={600} onClick={handleSonClose}
                                                    sx={{
                                                        fontSize: {
                                                            xs: '.75rem',
                                                            sm: '1.5rem',
                                                            lg: '2.25rem'
                                                        }

                                                    }}>{item.big_heading_right || ''}</Typography>
                                            </Typography>
                                            <Box id='abBBa' overflow='hidden'>
                                                <Typography color='secondary.light' variant="subtitle2" component='p' fontWeight={200} marginBottom={2}
                                                    paddingLeft={{
                                                        xs: 3,
                                                        sm: isExpandedSon ? 27.75 : 4,
                                                        md: isExpandedSon ? 2 : 4,
                                                        lg: isExpandedSon ? 0 : 4,
                                                    }}
                                                    lineHeight={1.4}>{item.description_right || 'No Description Right'}
                                                </Typography>

                                                <Typography color='secondary.light' variant="subtitle2" component='p' fontWeight={200}
                                                    paddingLeft={{
                                                        xs: 3,
                                                        sm: isExpandedSon ? 27.75 : 4,
                                                        md: isExpandedSon ? 2 : 4,
                                                        lg: isExpandedSon ? 0 : 4,
                                                        display: isExpandedSon ? 'flex' : 'none',
                                                        flexWrap: 'wrap',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                    lineHeight={1.4}
                                                >
                                                    <Typography component='span' variant='subtitle2' fontWeight={500} marginBottom={.5}>{item.description_right_read_more_heading || 'No Description Right'}</Typography>
                                                    <Box component='span'>{item.description_right_read_more || 'No Description Right'}</Box>
                                                </Typography>
                                            </Box>

                                            <Box
                                                // marginTop={{
                                                //     xs: 1.875,
                                                //     sm: 8,
                                                //     xl: 6.25
                                                // }}
                                                sx={{
                                                    transform: isExpandedSon ? 'translateY(20px)' : '',
                                                    opacity: isExpandedSon ? 0 : 1,
                                                    transition: "all 1.5s ease-in-out",
                                                }}
                                            >
                                                <AnimatedButton id='boCoBtBa' endIcon={<ArrowForwardOutlinedIcon className="arrow-icon" />} onClick={handleSonExpandClick} ref={sonButtonRef}>
                                                    <Typography component='span' className='MuiButton-label'
                                                        sx={{
                                                            fontSize: {
                                                                xs: '.5rem',
                                                                sm: '.875rem'
                                                            }
                                                        }}>Read More</Typography>
                                                </AnimatedButton>
                                            </Box>
                                        </Box>

                                    </Grid >
                                    {/* Son End */}
                                </Box >

                                {/* Director slides */}
                                <Container disableGutters maxWidth='lg'
                                    sx={{
                                        mt: {
                                            xs: 8.125,
                                            sm: 19.125
                                        },
                                        mb: {
                                            xs: 8.125,
                                            sm: 18.75
                                        }
                                    }}>
                                    <CarouselDirector />
                                    {/* <CarouselDirectorMobile /> */}
                                </Container >

                                <Container fixed sx={{
                                    px: {
                                        xs: 4.5,
                                        sm: 0
                                    }
                                }}>
                                    <Box position='relative'
                                        // className={animate && isDesktop ? 'wow animate__animated animate__fadeInLeft' : ''}
                                        // data-wow-duration="1s"
                                        // data-wow-delay="1s"
                                        // data-wow-offset='100'
                                        // data-wow-iteration='1'
                                        sx={{
                                            p: {
                                                xs: 5.625,
                                                sm: 10.375
                                            },
                                            py: {
                                                xs: 3.25
                                            },
                                            borderRadius: 2.5,
                                            mb: 4.375,
                                            background: `linear-gradient(${isDesktop ? '45deg' : '180deg'}, ${GlobalTheme.palette.primary.main} 0%, ${GlobalTheme.palette.primary.light} 110%)`,
                                            overflow: 'hidden',

                                            "&:hover::before": {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: '-100%',
                                                width: '200%',
                                                height: '100%',
                                                background: `linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent)`,
                                                transform: 'translateX(0)',
                                                animation: 'shine 2.5s  ease-in-out 1',
                                                animationFillMode: 'forwards'
                                            },

                                            "&:after": {
                                                backgroundImage: `url(${StarLogo})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                backgroundSize: {
                                                    xs: 'contain'
                                                },
                                                content: '""',
                                                display: 'flex',

                                                width: {
                                                    xs: 63,
                                                    sm: 155
                                                },
                                                height: {
                                                    xs: 63,
                                                    sm: '100%'
                                                },
                                                top: {
                                                    sm: 0
                                                },
                                                left: {
                                                    xs: 0,
                                                    sm: 'unset'
                                                },
                                                right: {
                                                    xs: 0,
                                                    sm: 63
                                                },
                                                margin: {
                                                    xs: '0 auto'
                                                },
                                                mt: {
                                                    xs: 4.375,
                                                    sm: 0
                                                },
                                                position: {
                                                    sm: 'absolute'
                                                },
                                                "@keyframes shine": {
                                                    from: {
                                                        transform: 'translateX(-100%)',
                                                    },
                                                    to: {
                                                        transform: 'translateX(100%)',
                                                    },
                                                },
                                            }

                                        }}>
                                        <Typography component='h3' fontWeight={400} color='common.white' marginBottom={4.375}
                                            sx={{
                                                fontSize: {
                                                    xs: '1.375rem',
                                                    sm: '2.25rem'
                                                }
                                            }}>{item.our_mission_hedaing || 'No Mission Heading'}</Typography>
                                        <Typography variant="h5" component='p' color='common.white' fontWeight={200}
                                            sx={{
                                                width: {
                                                    sm: '60%'
                                                },
                                                fontSize: {
                                                    xs: '.75rem',
                                                    sm: '1rem'
                                                }
                                            }}
                                        >
                                            {item.our_mission_description || 'No Mission Description'}
                                        </Typography>
                                    </Box>

                                    <Box position='relative'
                                        // className={animate && isDesktop ? 'wow animate__animated animate__fadeInRight' : ''}
                                        // data-wow-duration="1s"
                                        // data-wow-delay="1s"
                                        // data-wow-offset='100'
                                        // data-wow-iteration='1'
                                        sx={{
                                            p: {
                                                xs: 5.625,
                                                sm: 10.375
                                            },
                                            py: {
                                                xs: 8.125
                                            },
                                            borderRadius: 2.5,
                                            background: `linear-gradient(to bottom, ${GlobalTheme.palette.common.white} -95%, #E3E3E3 41%)`,
                                            overflow: 'hidden',

                                            "&:hover::before": {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: '-100%',
                                                width: '200%',
                                                height: '100%',
                                                background: `linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent)`,
                                                transform: 'translateX(0)',
                                                animation: 'shine 2.5s  ease-in-out 1',
                                                animationFillMode: 'forwards'
                                            },

                                            "&:after": {
                                                backgroundImage: `url(${isDesktop ? BankaLogo : mOurVisionLogo})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'bottom',
                                                content: '""',
                                                width: {
                                                    xs: 205,
                                                    // sm: 302,
                                                },
                                                height: '100%',
                                                bottom: 0,
                                                right: isDesktop ? 0 : -3,
                                                position: 'absolute',
                                            },
                                            "@keyframes shine": {
                                                from: {
                                                    transform: 'translateX(-100%)',
                                                },
                                                to: {
                                                    transform: 'translateX(100%)',
                                                },
                                            },
                                        }}>
                                        <Typography variant="h2" component='h3' fontWeight={400} marginBottom={4.375}
                                            sx={{
                                                position: 'relative',
                                                zIndex: 2,
                                                fontSize: {
                                                    xs: '1.375rem',
                                                    sm: '2.25rem'
                                                }
                                            }}>{item.our_vision_hedaing || 'No Vision Heading'}</Typography>
                                        <Typography variant="h5" component='p' fontWeight={200}
                                            sx={{
                                                position: 'relative',
                                                zIndex: 2,
                                                width: {
                                                    sm: '60%'
                                                },
                                                fontSize: {
                                                    xs: '.75rem',
                                                    sm: '1rem'
                                                }
                                            }}>
                                            {item.our_vision_description || 'No Vision Description'}
                                        </Typography>
                                    </Box >
                                </Container >

                                <Container fixed sx={{
                                    my: {
                                        xs: 8.125,
                                        sm: 18.75
                                    }
                                }}>
                                    <Box ref={ref}
                                    // className={animate && isDesktop ? 'wow animate__animated animate__flipInX' : ''}
                                    // data-wow-duration="1s"
                                    // data-wow-delay="1s"
                                    // data-wow-offset='100'
                                    // data-wow-iteration='1'
                                    >
                                        <Stack direction='row' justifyContent='space-between'>
                                            {counters.map((counter, index) => (
                                                <Stack
                                                    key={index}
                                                    direction={{
                                                        md: "row"
                                                    }}
                                                    spacing={2}
                                                    className="counter-item"
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <Typography key={index} component="h4" className="gradientText"
                                                        sx={{
                                                            fontSize: {
                                                                xs: '1.375rem',
                                                                md: '4rem'
                                                            }
                                                        }}>
                                                        <CountUp
                                                            start={counter.start}
                                                            end={counter.end}
                                                            duration={2}
                                                            redraw={inView}
                                                            className="bold"
                                                        />
                                                        <span className="thin">{counter.suffix}</span>
                                                    </Typography>
                                                    <Typography id={`counter${index}`} variant="subtitle1" component="p" textAlign="left"
                                                        sx={{
                                                            fontSize: {
                                                                xs: '.5rem',
                                                                sm: '1rem'
                                                            },
                                                            lineHeight: 1.2,
                                                            textAlign: {
                                                                xs: 'center',
                                                                md: 'left'
                                                            }
                                                        }}>
                                                        {counter.text.split("\n").map((line, idx) => (
                                                            <React.Fragment key={idx}>
                                                                {line}
                                                                <br />
                                                            </React.Fragment>
                                                        ))}
                                                    </Typography>
                                                </Stack>
                                            ))}
                                        </Stack>
                                    </Box>
                                </Container >
                            </>
                        ))
                        }
                    </>
                )
                }
            </Container >

            <MarqueeScroll />
            <Footer />



        </>
    );
};

export default AboutUS;
