import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import Footer from '../components/footer/footer.jsx';
import { GlobalTheme } from '../theme';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Stack, Typography, Snackbar, Alert, TextField, Card } from "@mui/material";
import HomeStyle from './assets/scss/style.module.scss';
import CareerBanner from './assets/images/career_banner.jpg';
import CareerBannerMobile from './assets/images/career_Mobile.jpg';
import ConnectStyle from './assets/scss/connect.module.scss';
import { Parallax } from 'react-parallax';
import useMediaQuery from '@mui/material/useMediaQuery';
import HelMet from '../components/helmet.jsx';
import { WOW } from 'wowjs';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const Career = ({ gridHeight }) => {
    const plHeBaRef = useRef(null);
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));
    const [scrollY, setScrollY] = useState(0);
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [generatedOtp, setGeneratedOtp] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        position_applied_for: '',
        resume: null,
    });
    const [errors, setErrors] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const validateForm = () => {
        let newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!formData.phone) {
            newErrors.phone = 'Phone number is required';
        } else if (!/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = 'Phone number must be 10 digits';
        }
        if (!formData.position_applied_for) newErrors.position_applied_for = 'Position is required';
        if (!formData.resume) newErrors.resume = 'Resume is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleOtpRequest = async () => {
        if (!validateForm()) return;

        const generatedOtpValue = Math.floor(100000 + Math.random() * 900000).toString();
        setGeneratedOtp(generatedOtpValue);

        try {
            await axios.post('https://bankacom.inpro1.fcomet.com/cmsapi/connect/send-otp', { email: formData.email, otp: generatedOtpValue });
            setSnackbar({ open: true, message: 'OTP sent to your email', severity: 'success' });
            setOtpSent(true);
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to send OTP.', severity: 'error' });
        }
    };

    const handleOtpSubmitconnect = async () => {
        if (otp !== generatedOtp) {
            setSnackbar({ open: true, message: 'Incorrect OTP.', severity: 'error' });
            return;
        }

        try {
            const response = await axios.post('https://bankacom.inpro1.fcomet.com/cmsapi/connect/verify', formData);
            setSnackbar({ open: true, message: response.data.message, severity: 'success' });
            setFormData({ name: '', businessName: '', email: '', phone: '', location: '', message: '' });
            setOtpSent(false);
            setOtp('');
            setGeneratedOtp('');
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to submit message.', severity: 'error' });
        }
    };
    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        if (otp !== generatedOtp) {
            setSnackbar({ open: true, message: 'Incorrect OTP.', severity: 'error' });
            return;
        }
        if (!validateForm()) return;

        const data = new FormData();
        data.append('name', formData.name);
        data.append('email', formData.email);
        data.append('phone', formData.phone);
        data.append('position_applied_for', formData.position_applied_for);
        if (formData.resume) data.append('resume', formData.resume);

        try {
            const response = await axios.post('https://bankacom.inpro1.fcomet.com/cmsapi/careers', data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setSnackbar({ open: true, message: response.data.message, severity: 'success' });
            setFormData({ name: '', email: '', phone: '', position_applied_for: '', resume: null });
            setOtpSent(false);
            setOtp('');
            setGeneratedOtp('');
            setSelectedFile(null);
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to submit application.', severity: 'error' });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: 'success' });
    };

    useEffect(() => {
        if (plHeBaRef.current) { // Check if ref is defined
            plHeBaRef.current.style.height = `${gridHeight}px`; // Set height from prop
        } else {
            console.warn("plHeBaRef is undefined");
        }
        // console.log("Grid Height in Career:", gridHeight);
    }, [gridHeight]);

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',       // Class to identify elements to animate
            animateClass: 'animate__animated', // Animation class from Animate.css
            offset: 100,           // Distance to start the animation (in px)
            mobile: false,          // Enable animations on mobile
            live: true,            // Recheck for new elements to animate on the page
        });
        wow.init(); // Initialize WOW.js
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prevData) => ({
                ...prevData,
                resume: e.target.files[0],
            }));
            setSelectedFile(file.name);
        }
    };
    // const handleFileChange = (e) => {
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         resume: e.target.files[0],
    //     }));
    // };
    const handleFileDelete = () => {
        setFormData((prevData) => ({
            ...prevData,
            resume: null,
        }));
        setSelectedFile(null);
    };

    return (
        <>
            <HelMet page_name="career" />
            <Box id='plHeBa' ref={plHeBaRef} >
                <Card sx={{ position: 'relative', height: 'inherit', borderRadius: 0 }}
                // className="wow animate__animated animate__fadeIn"
                // data-wow-duration="1s"
                // data-wow-delay="1s"
                // data-wow-offset='100'
                // data-wow-iteration='1'
                >
                    <Parallax
                        blur={isDesktop ? { min: Math.min(scrollY / 10, -15), max: Math.min(scrollY / 5, 15) } : ''}
                        bgImage={isDesktop ? CareerBanner : CareerBannerMobile}
                        strength={-100}

                        className='mantleBg'
                        style={{
                            height: '100%',
                            width: '100%',
                            display: "flex",
                        }}
                    >
                    </Parallax>
                </Card>

            </Box >

            <Container maxWidth='false' disableGutters sx={{ justifyContent: 'center', textAlign: 'center', pt: 7.37, pb: 6.625 }}>
                <Typography component='h2' variant='h2' color='secondary' position='relative'
                    // className="wow animate__animated animate__fadeIn"
                    // data-wow-duration="1s"
                    // data-wow-delay="1s"
                    // data-wow-offset='100'
                    // data-wow-iteration='1'
                    sx={{
                        position: 'relative',
                        fontSize: {
                            xs: '1.375rem',
                            lg: '2.25rem'
                        },

                        "&:before": {
                            content: '""',
                            height: 2,
                            width: '100%',
                            position: 'absolute',
                            transform: {
                                xs: 'translateY(12px)',
                                lg: 'translateY(22px)'
                            },
                            backgroundColor: 'secondary.main',
                            left: 0, right: 0
                        }
                    }}>
                    <Box component='span' position='relative' display='flex' justifyContent='center' sx={{
                        backgroundColor: GlobalTheme.palette.common.white,
                        width: {
                            xs: '70%',
                            sm: '40%',
                            md: '35%',
                            lg: '25%'
                        },
                        zIndex: 1, margin: '0 auto'
                    }}>
                        Join our radiant team
                    </Box>
                </Typography>
            </Container>

            <Container fixed sx={{ mb: 18.75 }}>
                <Stack component="form" onSubmit={handleOtpSubmit}
                // className="wow animate__animated animate__slideIn"
                // data-wow-duration="1s"
                // data-wow-delay="1s"
                // data-wow-offset='100'
                // data-wow-iteration='1'
                >
                    <TextField
                        name="name"
                        label="Name"
                        variant="standard"
                        value={formData.name}
                        onChange={handleChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        sx={{ mb: 4.375 }}
                    />
                    <TextField
                        name="email"
                        label="Email"
                        variant="standard"
                        value={formData.email}
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        sx={{ mb: 4.375 }}
                    />
                    <TextField
                        name="phone"
                        label="Phone"
                        variant="standard"
                        value={formData.phone}
                        onChange={handleChange}
                        error={Boolean(errors.phone)}
                        helperText={errors.phone}
                        sx={{ mb: 4.375 }}
                    />
                    <TextField
                        name="position_applied_for"
                        label="Position you are applying for"
                        variant="standard"
                        value={formData.position_applied_for}
                        onChange={handleChange}
                        error={Boolean(errors.position_applied_for)}
                        helperText={errors.position_applied_for}
                        sx={{ mb: 4.375 }}
                        multiline
                        minRows={2}
                        maxRows={6}
                    />
                    <Box justifyContent='end' display='flex'
                        marginTop={{
                            xs: 8.125,
                            lg: 12.5
                        }}>
                        <Stack direction={{ sm: 'row' }} justifyContent='space-between' alignItems='center' sx={{
                            width: '100%',
                            px: {
                                xs: 4.125,
                                sm: 0
                            }
                        }}>
                            <Box>
                                {!selectedFile && (
                                    <>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            className={HomeStyle.primaryBtn}
                                            sx={{
                                                px: 5, py: 3,
                                                mb: { xs: 5, sm: 0 },
                                                width: { xs: '100%', lg: 'auto' },
                                                backgroundColor: GlobalTheme.palette.common.white,
                                                borderRadius: 10,
                                                transition: 'all .3s easeIn',
                                            }}
                                        >
                                            Upload your CV
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={handleFileChange}
                                                accept=".pdf,.doc,.docx"
                                            />
                                        </Button>
                                        {errors.resume && <Typography color="error" sx={{ mt: 2 }}>{errors.resume}</Typography>}
                                    </>
                                )}
                                {/* File Name Preview and Delete Button */}
                                {selectedFile && (
                                    <Box

                                    >
                                        <Typography component='span' fontWeight={500}>Curriculum vitae</Typography>
                                        <Stack direction='row' alignItems='center' spacing={2}>

                                            <Typography variant="body2" sx={{ flex: 1 }}>
                                                {selectedFile}
                                            </Typography>
                                            <Tooltip title='Delete CV'>
                                                <IconButton onClick={handleFileDelete}>
                                                    <DeleteOutlinedIcon sx={{ fontSize: GlobalTheme.typography.subtitle1.fontSize }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>

                                    </Box>
                                )}
                            </Box>

                            <Box>
                                {/* OTP Verification Section */}
                                {otpSent ? (
                                    <>
                                        <TextField
                                            label="Enter OTP"
                                            variant="standard"
                                            value={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                            sx={{
                                                mb: 4.375
                                            }}
                                            fullWidth={false}
                                        />
                                        <Box sx={{ px: 5, textAlign: 'center' }}>
                                            <Button onClick={handleOtpSubmit} variant="contained" className={`${ConnectStyle.btnStyle} ${ConnectStyle.connectBtn}`}
                                                sx={{
                                                    width: { xs: '100%', xl: 'auto' },
                                                    py: 1.875,
                                                    px: 3.125,
                                                    borderRadius: 7.5,
                                                    backgroundColor: GlobalTheme.palette.common.white,
                                                    fontWeight: 400,
                                                    color: '#0E1D42'
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </>
                                ) : (
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Button onClick={handleOtpRequest} variant="contained" className={`${ConnectStyle.btnStyle} ${ConnectStyle.connectBtn}`}
                                            sx={{
                                                width: { xs: '100%', lg: 'auto' },
                                                py: 1.875,
                                                px: 3.125,
                                                borderRadius: 7.5,
                                                backgroundColor: GlobalTheme.palette.common.white,
                                                fontWeight: 400,
                                                color: '#0E1D42'
                                            }}
                                        >
                                            Send Otp
                                        </Button>
                                    </Box>
                                )}

                            </Box>
                        </Stack>
                    </Box>

                </Stack>
            </Container>


            <Footer />

            {/* Snackbar for success/error messages */}
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Career;
