import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for API calls
import { Swiper, SwiperSlide } from 'swiper/react';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import { Box, Divider, Typography } from '@mui/material';

import Intro from './assets/video/intro.mp4';
import HomeStyle from './assets/scss/home.module.scss';
import { common } from '@mui/material/colors';
import { GlobalTheme } from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Autoplay } from 'swiper/modules';

import './assets/scss/ani.scss';

const baseImagePath = 'https://bankacom.inpro1.fcomet.com/api/backend/uploads';

const AnimatedMantle = ({ gridHeight }) => {
    const plHeBaRef = useRef(null);
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));
    const [imageHeight, setImageHeight] = useState(0);
    const [slidesData, setSlidesData] = useState([]);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    // Function to fetch data from the API
    const fetchSlidesData = async () => {
        try {
            const response = await axios.get('https://bankacom.inpro1.fcomet.com/backend/table-data/banka_home_banner');
            const filteredData = response.data.data.filter(slide => slide.id !== 1); // Filter out records where id === 1
            setSlidesData(filteredData); // Save the filtered data
        } catch (error) {
            console.error('Error fetching home banner data:', error);
        }
    };

    useEffect(() => {
        fetchSlidesData();
    }, []);


    const calculateImageHeight = () => {
        if (plHeBaRef.current) {
            const boxHeight = plHeBaRef.current.offsetHeight;
            setImageHeight(boxHeight); // Set the height of the image
        }
    };

    useEffect(() => {
        calculateImageHeight();
        const resizeObserver = new ResizeObserver(calculateImageHeight);

        if (plHeBaRef.current) {
            const boxHeight = plHeBaRef.current.offsetHeight;
            resizeObserver.observe(plHeBaRef.current);
            setImageHeight(boxHeight);
            plHeBaRef.current.style.height = `${gridHeight}px`; // Set height from prop
        }
        return () => {
            resizeObserver.disconnect();
        };
    }, [gridHeight]); // Recalculate on gridHeight change


    const onAutoplayTimeLeft = (s, time, progress) => {
        if (progressCircle.current) {
            progressCircle.current.style.setProperty('--progress', 1 - progress);
        }
        if (progressContent.current) {
            progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
        }
    };
    const handleSlideChange = (swiper) => {
        setCurrentSlideIndex(swiper.realIndex);
    };

    const triggerAnimations = (index) => {
        const txtHeaBa = document.getElementById(`txtHeaBa-${index}`);
        const txtSubHeaBa = document.getElementById(`txtSubHeaBa-${index}`);
        const dividerBa = document.getElementById(`dividerBa-${index}`);
        const txtDescBa = document.getElementById(`txtDescBa-${index}`);

        if (txtHeaBa) txtHeaBa.classList.add('animate__fadeInDown');
        if (txtSubHeaBa) txtSubHeaBa.classList.add('animate__fadeInRight');
        if (dividerBa) dividerBa.classList.add('animate__fadeInLeft');
        if (txtDescBa) txtDescBa.classList.add('animate__fadeInLeft');
    };
    // Trigger animations when slidesData is loaded for the first time
    useEffect(() => {
        if (slidesData.length > 0) {
            triggerAnimations(0); // Trigger animations for the first slide
        }
    }, [slidesData]);

    useEffect(() => {
        triggerAnimations(currentSlideIndex);
    }, [currentSlideIndex]);

    useEffect(() => {
        if (!progressCircle.current || !progressContent.current) {
            console.warn("Progress elements not yet available.");
        }
    }, []);

    return (
        <>
            <Box id='hoMaBa' ref={plHeBaRef} component='section' position='relative'
                sx={{
                    height: imageHeight,
                    // height: 487,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    animation: 'fadeIn 2s ease-in-out'
                }}>
                <Box display='flex' justifyContent='center'>
                    <Box
                        id='introVid'
                        position='absolute'
                        bottom={{
                            xs: -174,
                            lg:-120,
                            // lg: -132,
                            xl: -94
                        }}
                        zIndex={2}
                        className={HomeStyle.logo_mask}
                        sx={{
                            transform: {
                                xs: 'scale(.5)',
                                lg:'scale(.8)',
                                // lg: 'scale(.7)',
                                xl: 'scale(1)'
                            }
                        }}
                    >
                        <Box className={HomeStyle.video_holder}>
                            <CardMedia
                                id="mantleMedia"
                                component="video"
                                height="416"
                                autoPlay
                                loop
                                muted
                                playsInline
                                loading='lazy'
                                sx={{
                                    transform: 'scale(1.5)'
                                }}
                            >
                                <source src={Intro} type="video/mp4" />
                            </CardMedia>
                        </Box>
                    </Box>
                </Box>
                <Swiper
                    spaceBetween={0}
                    centeredSlides={true}
                    autoplay={{
                        delay: 4000, disableOnInteraction: false
                    }}

                    onSlideChange={handleSlideChange}
                    modules={[Autoplay]}
                    // className=
                    className={`homeMantle ${HomeStyle.adjMantleBottom}`}
                >
                    {slidesData.map((slide, index) => (
                        <SwiperSlide id={`mantle${index}`} key={index}>
                            <Box display='flex' flexWrap='wrap' justifyContent='center' position='relative' paddingTop={1}>
                                <Stack position='relative'>
                                    <Box position='absolute'
                                        bottom={{
                                            xs: 0,
                                            lg: '.5rem'
                                        }}
                                        left={0}
                                        right={{ xs: 0 }}
                                        width={{
                                            xs: '50%',
                                            lg:'36%',
                                            // lg: '40%',
                                            xl: '40%'
                                        }}
                                        textAlign={{ lg:'right' }}
                                        sx={{
                                            transform: {
                                                lg:'translateX(-56px)',
                                                xl: 'translateX(-90px)',
                                            },
                                            margin: {
                                                xs: '0 auto',
                                                lg:'unset'
                                            },
                                            zIndex: 10
                                        }}>
                                        <Typography variant="h1" color={common.white} fontWeight={200}
                                            sx={{
                                                fontSize: {
                                                    lg:'3rem',
                                                    // lg: '1.8rem',
                                                },
                                            }}
                                        >
                                            <Stack component='span'>
                                                <Box component='span'
                                                    id={`txtHeaBa-${index}`}

                                                    className='animate__animated'

                                                    position='relative'
                                                    sx={{
                                                        top: 13
                                                    }}
                                                >
                                                    {slide.heading}
                                                </Box>
                                                <Typography
                                                    id={`txtSubHeaBa-${index}`}

                                                    variant="h2"
                                                    component='span'
                                                    fontWeight={500}

                                                    sx={{
                                                        fontFamily: 'OPTIOnyx',
                                                        fontSize: {
                                                            xs: '2rem',
                                                            lg: '4rem',
                                                            xl: '5rem',
                                                        },
                                                        mb: 1.25
                                                    }}
                                                    className='animate__animated'

                                                >
                                                    {slide.sub_heading}
                                                </Typography>
                                            </Stack>
                                        </Typography>
                                    </Box>
                                    <Box position='absolute' bottom={0} right={0} width={{ xs: '100%', lg:'36%', zIndex: 10 }}
                                        sx={{
                                            transform: {
                                                lg:'translate(-7px, -50px)',
                                                // lg: 'translate(-25px, -28px)',
                                                xl: 'translate(-30px, -30px)',
                                            }
                                        }}>
                                        <Stack direction='row' spacing={2} alignItems='center'>
                                            <Divider
                                                id={`dividerBa-${index}`}

                                                sx={{ borderColor: common.white, width: '42%', height: 2 }}
                                                className="animate__animated"
                                            />
                                            <Box width='50%' id={`txtDescBa-${index}`} className="animate__animated ">
                                                <Typography variant="h4" component='p' color={common.white} fontWeight={200}
                                                    textAlign='left'
                                                    sx={{
                                                        fontSize: {
                                                            xs: '.75rem',
                                                            lg:'1.125rem',
                                                            // lg: '.875rem',
                                                            xl: '1.125rem',
                                                        }
                                                    }}>
                                                    {slide.description}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Box>

                                    <img
                                        src={`${baseImagePath}/${isDesktop ? slide.image_Banner : slide.image_banner_mobile}`}
                                        alt={slide.heading}
                                        style={{
                                            height: imageHeight,
                                            width: '100%',
                                        }}
                                        className={`animate__animated animate__fadeIn ${HomeStyle.image}`}
                                    />
                                </Stack>
                            </Box>

                            <div className="autoplay-progress" slot="container-end">
                                <svg viewBox="0 0 48 48" ref={progressCircle}>
                                    <circle cx="24" cy="24" r="20"></circle>
                                </svg>
                                <span ref={progressContent}></span>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper >
            </Box>
        </>
    );
}

export default AnimatedMantle;