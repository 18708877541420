import { Box, Typography, Link, List, ListItem, Container } from '@mui/material';
import Grid from '@mui/material/Grid2';
import LogoSecondaryBanka from './assets/images/logo-secondary.png';
import CardMedia from '@mui/material/CardMedia';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import Watermark from './assets/images/watermark.svg';
import { GlobalTheme } from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import FooterStyle from './assets/scss/style.module.scss';

const socialMediaLinks = [
    { href: 'https://www.instagram.com/banka1964official', icon: <InstagramIcon /> },
    { href: 'https://www.facebook.com/banka1964official', icon: <FacebookOutlinedIcon /> },
    { href: 'https://www.linkedin.com/in/raghav-banka-603922a9', icon: <LinkedInIcon /> },
];

const footerMenuItems = [
    { text: 'Events', href: '/Events' },
    { text: 'Connect', href: '/Connect' },
    { text: 'Origin', href: '/About-us' },
    { text: 'Collections', href: '/Collections' },
    { text: 'Career', href: '/Career' }
];

const Footer = () => {
    const isMobile = useMediaQuery(GlobalTheme.breakpoints.down('lg'));
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));

    return (
        <Box
            sx={{
                backgroundColor: 'secondary.lighter',
                color: 'white',
            }}
            component='footer'
            // className="wow animate__animated animate__fadeIn"
            data-wow-duration="1s"
            data-wow-delay="1s"
            data-wow-offset='100'
            data-wow-iteration='1' >
            <Container maxWidth="xl">
                <Grid container justifyContent='space-between'
                    sx={{
                        backgroundImage: `url(${Watermark})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom right',
                        backgroundSize: { xs: 'contain', lg: 'revert' },
                        pb: { xs: 15, lg: 3.125 }
                    }}>

                    <Grid
                        item
                        size={{ xs: 12, lg: 4, }}
                        marginTop={{
                            xs: 8.125,
                            lg: 13.5
                        }}
                        display='flex'
                        flexWrap='wrap'
                        flexDirection='column'
                        alignContent={{ xs: 'center', lg: 'flex-start' }}
                    >
                        <CardMedia
                            component="img"
                            image={LogoSecondaryBanka}
                            alt="Logo Banka"
                            loading='lazy'
                            sx={{
                                objectFit: 'contain',
                                width: 'auto',
                                height: {
                                    xs: 103,
                                    lg: 183
                                }
                            }}
                        />
                        {isDesktop && (
                            <Typography color='secondary.lightest' component='p' variant='subtitle2' fontWeight='400' sx={{ mt: 10.811 }}>
                                Copyright &copy; 2019 All Rights Reserved.
                                <Typography variant='caption' sx={{ display: 'block' }}>
                                    Powered by Banka 1964
                                </Typography>
                            </Typography>
                        )}
                    </Grid>

                    <Grid item size={{ xs: 12, lg: 6, }}
                        sx={{
                            pt: { lg: 12.12 },
                        }}>
                        <Grid container justifyContent='end' sx={{ borderLeft: { lg: `1px solid ${GlobalTheme.palette.general.grayLightest}` } }}>
                            <Grid item size={{ xs: 12, lg: 2 }} sx={{
                                py: {
                                    xs: 9.5,
                                    lg: 0
                                }
                            }}>
                                <List>
                                    {footerMenuItems.map((item, index) => (
                                        <ListItem key={index} sx={{ pb: 0, pt: 1.875, justifyContent: { xs: 'center', lg: 'flex-start' } }}>
                                            <Link id={`fo${index}`} key={index} href={item.href} color="secondary.light" underline="none"
                                                className={FooterStyle.anim}>
                                                {item.text}
                                            </Link>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item size={{ xs: 12, lg: 8 }} >
                                <Box component='address' sx={{ fontStyle: 'normal', mt: { lg: 2 } }}>
                                    <Typography variant="body2" color="secondary.light" textAlign={{ xs: 'center', lg: 'right' }}>
                                        2nd Floor Balaji Tower
                                        <Typography variant='caption' sx={{ display: 'block' }}>
                                            135 Cotton Street, Kolkata 700 007
                                        </Typography>
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 1.875, textAlign: { xs: 'center', lg: 'right' } }}>
                                    <Link
                                        id='emBa'
                                        href='mailto:info@banka1964.com'
                                        target="_blank"
                                        underline='none'
                                        rel="noreferrer noopener"
                                        className={FooterStyle.anim}
                                        sx={{
                                            fontWeight: { xs: 200, lg: 300 }
                                        }}
                                    >info@banka1964.com</Link>
                                </Box>
                                <Box display='flex' justifyContent={{ xs: 'center', lg: 'end' }} sx={{ mt: { xs: 3.625, lg: 11.75 } }}>
                                    <List sx={{ display: 'inline-flex', p: 0 }}>
                                        {socialMediaLinks.map((link, index) => (
                                            <ListItem key={index} disablePadding>
                                                <Link
                                                    id={`soBa${index}`}
                                                    key={index}
                                                    href={link.href}
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                    color='secondary.lightest'
                                                    sx={{
                                                        mr: index !== socialMediaLinks.length - 1 ? 1.875 : 0,
                                                    }}
                                                    className={FooterStyle.anim}
                                                >
                                                    {link.icon}
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                                {isMobile && (
                                    <Typography color='secondary.lightest' component='p' textAlign='center' variant='subtitle2' fontWeight='400' sx={{ mt: 5.625 }}>
                                        Copyright &copy; 2019 All Rights Reserved.
                                        <Typography variant='caption' sx={{ display: 'block' }}>
                                            Powered by Banka 1964
                                        </Typography>
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    );
};

export default Footer;
