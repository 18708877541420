import React, { useState, useEffect, forwardRef } from 'react';
import { useMediaQuery, Toolbar, BottomNavigation, Button, Accordion, AccordionSummary, AccordionDetails, Drawer, List, ListItem, Link, Typography, Box } from '@mui/material';

import { Link as bAlink } from 'react-router-dom';

import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { GlobalTheme } from '../../theme';

import './assets/scss/menu.css';

const MobileNav = forwardRef((props, ref) => {
    const isMobile = useMediaQuery(GlobalTheme.breakpoints.down('lg'));
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);

    // State for categories and subcategories
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState({});

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expanded, setExpanded] = useState('panel1');
    const encodeBase64 = (id) => {
        return btoa(id.toString());
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : expanded); // Toggle the accordion panels
    };

    const handleDropdownToggle = (dropdown) => {
        if (activeDropdown === dropdown) {
            setActiveDropdown(null); // Close if it's already open
        } else {
            setActiveDropdown(dropdown);
            const megaMenuElement = document.getElementById('megMeBa');
            if (megaMenuElement) {
                megaMenuElement.removeAttribute('style');
            }
        }
    };

    const handleMenuItemClick = () => {
        setAnchorEl(null);
        setExpanded('');
        window.scrollTo(0, 0);
    };

    const [state, setState] = React.useState({
        bottom: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //     return;
        // }

        setState({ ...state, [anchor]: open });
    };

    // Fetch categories and subcategories on component mount
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                // Fetch categories
                const categoryResponse = await axios.get('https://bankacom.inpro1.fcomet.com/backend/table-data/banka_category');
                setCategories(categoryResponse.data.data);

                // Fetch subcategories for each category
                const subCategoryResponse = await axios.get('https://bankacom.inpro1.fcomet.com/backend/table-data/banka_sub_category');
                const subCategoriesByCategory = {};
                subCategoryResponse.data.data
                    .sort((a, b) => a.appear_order - b.appear_order) // Sort by appear_order in ascending order
                    .forEach((subCategory) => {
                        if (!subCategoriesByCategory[subCategory.category_id]) {
                            subCategoriesByCategory[subCategory.category_id] = [];
                        }
                        subCategoriesByCategory[subCategory.category_id].push(subCategory);
                    });
                setSubCategories(subCategoriesByCategory);
                // console.log(subCategoriesByCategory);
            } catch (error) {
                console.error('Error fetching categories or subcategories:', error);
            }
        };

        fetchCategories();
    }, []);


    return (
        <>
            {isMobile && (
                <Toolbar component='nav' id='moNaBa' ref={ref} sx={{ width: '100%', position: 'fixed', bottom: 0, left: 0, right: 0, }}>
                    <BottomNavigation
                        showLabels
                        sx={{
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: 107
                        }}

                    >
                        <Link component={bAlink} to="/About-us" underline="none" sx={{fontWeight: 500}}>Origin</Link>
                        {['bottom'].map((anchor) => (
                            <React.Fragment key={anchor}>
                                <Button id="coMeBa" onClick={toggleDrawer(anchor, true)} sx={{fontSize: '1rem'}}>Collections</Button>
                                <Drawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                    onClick={(event) => {
                                        // Close the drawer only if clicking on the overlay and not on drawer content
                                        if (event.target === event.currentTarget) toggleDrawer(anchor, false)(event);
                                    }}
                                >
                                    <Box
                                        id="coMeExBa"
                                        sx={{ width: anchor === 'bottom' ? 'auto' : 250 }}
                                        role="presentation"
                                    >
                                        {categories.map((category) => (
                                            <Accordion
                                                key={category.id}
                                                expanded={expanded === category.id}
                                                onChange={handleAccordionChange(category.id)}
                                                elevation={0}
                                                sx={{
                                                    minHeight: 50, mb: 0,
                                                    "&:before": {
                                                        display: 'none'
                                                    }
                                                }}
                                                defaultExpanded
                                                onClick={(event) => event.stopPropagation()}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`panel-${category.id}-content`}
                                                    id={`panel-${category.id}-header`}
                                                >
                                                    <Typography component='h2'>{category.category}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{
                                                    py: 0
                                                }}>
                                                    <List sx={{ maxHeight: 200, overflowY: 'auto', py: 0 }}>
                                                        {subCategories[category.id]?.map((subCategory) => (
                                                            <ListItem key={subCategory.id} >
                                                                <Link
                                                                    id={`subCatBa${subCategory.id}`}
                                                                    component={bAlink}
                                                                    to={`/${category.category}/${subCategory.sub_category.replace(/\s+/g, '-')}/bk/${encodeBase64("Banka1964-"+subCategory.id)}`}
                                                                    className="dropdown-link"
                                                                    underline='none'
                                                                    onClick={() => {
                                                                        handleMenuItemClick();
                                                                        toggleDrawer(anchor, false)(); // Close drawer when menu item is clicked
                                                                    }}
                                                                    sx={{
                                                                        color: GlobalTheme.palette.secondary.lightest,
                                                                        "&:hover, &:focus": {
                                                                            color: GlobalTheme.palette.secondary.main,
                                                                        }
                                                                    }}>
                                                                    {subCategory.sub_category}
                                                                </Link>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </AccordionDetails>
                                            </Accordion>

                                        ))}
                                    </Box>
                                </Drawer>
                            </React.Fragment>
                        ))}
                        <Link
                            component={bAlink}
                            to="/Connect"
                            sx={{
                                fontWeight: 500,
                                backgroundImage: 'linear-gradient(to right, #49AAF0, #0E1D42)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                backgroundSize: '200% auto',
                                transition: 'background-position 0.5s ease',
                                textDecoration: 'none',
                                display: 'inline-block',

                                '&:hover': {
                                    // backgroundSize: '100% auto',
                                    backgroundPosition: 'right center', // Change the position of the gradient on hover
                                },
                            }}
                        >
                            Connect
                        </Link>
                    </BottomNavigation>
                </Toolbar >
            )}
        </>
    );
});

export default MobileNav;
