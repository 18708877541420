import React, { useRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './App.css';
import 'animate.css';
import { useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalTheme } from './theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Preloader from './components/preloader/preloader.jsx';
import MantleHome from './components/mantle/homeMantle.jsx';
import MantleHomeMobile from './components/mantle/homeMantleMobile.jsx';
import Header from './components/header/header';
import MobileNav from './components/header/mobileNav.jsx';
import Home from './pages/home.jsx';
import PLP from './pages/plp.jsx';
import PlpAll from './pages/plpAll.jsx';
import PlpCategory from './pages/PlpCategory.jsx';
import PopularProduct from './pages/popularProduct.jsx';
import ValleyPlp from './pages/valleyPlp.jsx';
import Grid from '@mui/material/Grid2';
import AboutUS from './pages/about-us.jsx';
import Events from './pages/events.jsx';
import Connect from './pages/connect.jsx';
import Career from './pages/career.jsx';
import ScrollToTop from './scrollToTop.jsx';
import Stats from './stats.jsx';
function App() {
  
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(GlobalTheme.breakpoints.down('lg'));
  const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));

  // Refs to hold the height of Header and MobileNav
  const headerRef = useRef(null);
  const mobileNavRef = useRef(null);
  const gridRef = useRef(null); // Ref for the scrolling Grid container
  // State to store the calculated height of Grid
  const [gridHeight, setGridHeight] = useState('auto');

  // Function to calculate height dynamically
  const calculateGridHeight = () => {
    const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
    const mobileNavHeight = mobileNavRef.current ? mobileNavRef.current.offsetHeight : 0;
    const windowHeight = window.innerHeight;
    // const calculatedHeight = windowHeight - headerHeight - mobileNavHeight;
    // setGridHeight(calculatedHeight);

    if (isMobile) {
      // For mobile: subtract Header and MobileNav height
      setGridHeight(windowHeight - headerHeight - mobileNavHeight);
    } else {
      // For desktop: subtract only the Header height
      setGridHeight(windowHeight - headerHeight);
    }
  };


  // Recalculate grid height on window resize
  useEffect(() => {
    calculateGridHeight();
    window.addEventListener('resize', calculateGridHeight);

    return () => {
      window.removeEventListener('resize', calculateGridHeight);
    };
  }, [isMobile]);  // Only recalculate when `isMobile` changes



  return (
    <>
      <ThemeProvider theme={GlobalTheme}>
        <CssBaseline />
        
        {!loading && (
          <Router>
            <>
            <Preloader />
            <Stats />
            <ScrollToTop scrollContainerRef={gridRef} />
              {
                isMobile && (
                  <>
                    <Header ref={headerRef} />
                    <Grid
                    component="main"
                    ref={gridRef}
                    sx={{
                      height: gridHeight,
                      overflowY: 'auto',
                    }}
                  >
                    {/* <Grid component='main'
                      sx={{
                        height: isMobile ? gridHeight : 'revert',
                        overflowY: 'auto',
                      }}> */}
                      
                      <Routes>
                        <Route path="/" element={
                          <>
                            <MantleHomeMobile />
                            <Home gridHeight={gridHeight} />
                          </>
                        }
                        />
                         <Route path="/:main_category_name/:category_name/bk/:sid" element={<PLP gridHeight={gridHeight} />} />
                        <Route path="/Collections" element={<PlpAll gridHeight={gridHeight} />} />
                        <Route path="/:category/ct/:cid" element={<PlpCategory gridHeight={gridHeight} />} />
                        <Route path="/:category/Popular-product" element={<PopularProduct gridHeight={gridHeight} />} />
                        <Route path="/Valley-of-ornaments/:cid" element={<ValleyPlp gridHeight={gridHeight} />} />
                        <Route path="/About-us" element={<AboutUS />} />

                        <Route path="/Events" element={<Events gridHeight={gridHeight} />} />

                        <Route path="/Connect" element={<Connect />} />
                        <Route path="/Career" element={<Career gridHeight={gridHeight} />} />
                      </Routes>
                    </Grid>

                    <MobileNav ref={mobileNavRef} />
                  </>
                )}

              {
                isDesktop && (
                  <>
                    <Header ref={headerRef} />

                    <Grid component='main'>
                      <Routes>

                        <Route path="/" element={
                          <>
                            <MantleHome gridHeight={gridHeight} />
                            <Home gridHeight={gridHeight} />
                          </>
                        }
                        />
                        <Route path="/:main_category_name/:category_name/bk/:sid" element={<PLP gridHeight={gridHeight} />} />
                        <Route path="/Collections" element={<PlpAll gridHeight={gridHeight} />} />
                        <Route path="/:category/ct/:cid" element={<PlpCategory gridHeight={gridHeight} />} />
                        <Route path="/:category/Popular-product" element={<PopularProduct gridHeight={gridHeight} />} />
                        <Route path="/Valley-of-ornaments/:cid" element={<ValleyPlp gridHeight={gridHeight} />} />
                        <Route path="/About-us" element={<AboutUS />} />

                        <Route path="/Events" element={<Events gridHeight={gridHeight} />} />

                        <Route path="/Connect" element={<Connect />} />
                        <Route path="/Career" element={<Career gridHeight={gridHeight} />} />
                      </Routes>
                    </Grid>
                  </>
                )}
            </>
          </Router>
        )}
      </ThemeProvider>
    </>
  );
}

export default App;
