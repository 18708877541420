import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';

import { visuallyHidden } from '@mui/utils';

import { WOW } from 'wowjs';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Pagination, Autoplay } from 'swiper/modules';
import { Parallax } from 'react-parallax';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import { CardMedia, Typography } from "@mui/material";
import HomeStyle from './assets/scss/style.module.scss';

import { GlobalTheme } from "../theme.js";

import MantleHome from './../components/mantle/homeMantle.jsx';
import MarqueeScroll from '../components/marque/marque.jsx';
import Footer from '../components/footer/footer.jsx';
import Link from '@mui/material/Link';
import { Link as bAlink } from 'react-router-dom';

import SlickSlider from './slickSlider.jsx';
import SpectrumMobile from './spectrumMobile.jsx';
import './assets/scss/popularView.scss';
import HelMet from '../components/helmet.jsx';
import PopularPicks from './popularPicks.jsx';

const Home = ({ gridHeight }) => {
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));
    const baseImageUrl = 'https://bankacom.inpro1.fcomet.com/api/cmsapi/uploads';
    const baseBImageUrl = 'https://bankacom.inpro1.fcomet.com/api/backend/uploads';
    const [scategories, setsCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [specProducts, setspecProducts] = useState([]);

    const [categories, setCategories] = useState([]);
    const categoryIdFromUrl = 1;  // This should come from your logic to extract from the URL
    const items = Array.from({ length: 15 }, (_, index) => ({
        id: index + 1,
        image: `https://via.placeholder.com/150?text=Item+${index + 1}`,
        title: `Item ${index + 1}`,
    }));

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };


    // Filter subcategories and products for the category where `product.show_in_spectrum === 1`
    const filteredProducts = products.filter(product => {
        // Find the subcategory that matches the category_id from URL and show_in_spectrum flag
        const subcategory = subcategories.find(subcat => subcat.id === product.sub_category_id && subcat.category_id === categoryIdFromUrl);

        // Check if the product belongs to this category and is marked as show_in_spectrum
        return subcategory && product.show_in_spectrum === 1;
    });

    // filteredProducts now contains all products for the category where show_in_spectrum === 1
    // console.log(filteredProducts);
    const [slidesData, setSlidesData] = useState([]);
    const fetchSlidesData = async () => {
        try {
            const response = await axios.get('https://bankacom.inpro1.fcomet.com/backend/table-data/banka_valley_banner');
            const filteredData = response.data.data.filter(slide => slide.id !== 1); // Filter out records where id === 1
            setSlidesData(filteredData); // Save the filtered data
        } catch (error) {
            console.error('Error fetching valley banner data:', error);
        }
    };

    useEffect(() => {
        const fetchCategories = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_category`);


        Promise.all([fetchCategories])
            .then(([categoryResponse]) => {
                setCategories(categoryResponse.data.data);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',       // Class to identify elements to animate
            animateClass: 'animate__animated', // Animation class from Animate.css
            offset: 100,           // Distance to start the animation (in px)
            mobile: false,          // Enable animations on mobile
            live: true,            // Recheck for new elements to animate on the page
        });
        wow.init(); // Initialize WOW.js
    }, []);


    useEffect(() => {
        const fetchCategories = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_category`);
        const fetchSubcategories = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_sub_category`);
        const fetchProducts = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_product`);


        Promise.all([fetchCategories, fetchSubcategories, fetchProducts])
            .then(([categoryResponse, subcategoryResponse, productResponse]) => {
                setsCategories(categoryResponse.data.data);
                setSubcategories(subcategoryResponse.data.data);
                setProducts(productResponse.data.data);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        fetchSlidesData();

    }, []);
    const encodeBase64 = (id) => {
        return btoa(id.toString());
    };
    return (
        <>
            {/* <MantleHome /> */}
            <HelMet page_name="home" />
            <Container fixed component='section' aria-labelledby="abo" sx={{
                py: {
                    xs: 12.875,
                    md: 27.75
                },

                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap'
            }}
                // className="wow animate__animated animate__fadeIn"
                // data-wow-duration="1s"
                // data-wow-delay="1s"
                // data-wow-offset='100'
                // data-wow-iteration='1'
            >
                <Typography
                    id='aboBa'
                    component='h2'
                    variant="h2"
                    fontWeight={500}
                    sx={{
                        fontSize: {
                            xs: '1.375rem',
                            lg: '2.25rem'
                        },
                        background: (theme) =>
                            `linear-gradient(315deg, ${theme.palette.primary.main} 62%, ${theme.palette.primary.light} 100%)`,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        textAlign: 'justify',
                        backgroundSize: '200% 200%',
                        animation: 'gradientMove 25s ease infinite',
                        '@keyframes gradientMove': {
                            '0%': {
                                backgroundPosition: 'top left',
                            },
                            '100%': {
                                backgroundPosition: 'bottom right',
                            },
                        },
                    }}
                >The Banka Legacy</Typography>
                <Typography
                    variant='h4'
                    component='p'
                    sx={{
                        my: 6.25,
                        lineHeight: 1.6,
                        px: {
                            xs: 5.625,
                            lg: 4,
                            lg: 20.5
                        },
                        fontSize: {
                            xs: '.75rem',
                            lg: '1.375rem'
                        },
                        textAlign: 'center'
                    }}
                    color={GlobalTheme.palette.secondary.light}
                    fontWeight={300}>
                    {/* With a passion for creating exceptional Indian-made jewellery that endures, Banka’s journey started in Sambalpur in 1964 holding the hand of the late Sukhdeo Banka. To carry forward the the Banka legacy with even more innovation, the Banka brothers Raghav Banka, and Rahul Banka launched <strong>Banka 1964</strong>. */}
                    The saga of Banka legacy unfolds across generations, beginning with Sri Sukhdeo Lal Banka, whose journey commenced at a precocious age of 16. With a passion for creating exceptional Indian-made jewellery that endures, the Banka brothers Raghav and Rahul Banka launched <br/> "<strong>Banka 1964</strong>" under the guidance of Raj Kishore Banka.
                </Typography>
                <Link
                    id='btnReadMoreBanLeg'
                    className={HomeStyle.primaryBtn}
                    component={bAlink}
                    to="/About-us"
                    sx={{
                        fontSize: GlobalTheme.typography.subtitle2.fontSize,
                        py: 1.875,
                        px: 3.125,
                        backgroundColor: GlobalTheme.palette.common.white,
                        borderRadius: 7.5,
                        color: GlobalTheme.palette.primary.main,
                        fontWeight: 400
                    }}
                    underline="none">
                    Read More
                </Link>
            </Container >


            <PopularPicks />

            <Container disableGutters maxWidth={false} component='section' aria-labelledby="soc"
                sx={{
                    py: 8.125,
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            // className="wow animate__animated animate__fadeIn"
            // data-wow-duration="1s"
            // data-wow-delay="1s"
            // data-wow-offset='100'
            // data-wow-iteration='1'
            >

                <Container maxWidth='false' disableGutters sx={{
                    justifyContent: 'center', textAlign: 'center',
                    pb: 6.25,
                }}>
                    <Typography id='soc' component='h2' variant='h2' textAlign='center' color='general.bodyTxt'
                        sx={{
                            position: 'relative',
                            fontSize: {
                                xs: '1.375rem',
                                lg: '2.25rem'
                            },

                            "&:before": {
                                content: '""',
                                height: 2,
                                width: '100%',
                                position: 'absolute',
                                transform: {
                                    xs: 'translateY(12px)',
                                    lg: 'translateY(22px)'
                                },
                                backgroundColor: 'primary.main',
                                left: 0, right: 0,
                                // mb: 8.125
                            }
                        }}>
                        <Box component='span' position='relative' display='flex' justifyContent='center' sx={{
                            backgroundColor: GlobalTheme.palette.common.white,
                            width: {
                                xs: '70%',
                                lg: '55%',
                                md: '42%',
                                lg: '32%'
                            },
                            zIndex: 1, margin: '0 auto'
                        }}>
                            Spectrum of Chains
                        </Box>
                    </Typography>
                </Container>

                <Container maxWidth={false} disableGutters sx={{ position: 'relative' }}>
                    <Box position='relative' sx={{ width: '100%' }}>
                        {
                            isDesktop ? (
                                <SlickSlider />
                            ) : (
                                <Container>
                                    <SpectrumMobile />

                                </Container>
                            )
                        }

                    </Box>
                </Container>

                <Box marginTop={9}>
                    <Link component={bAlink} variant='subtitle2' className={HomeStyle.primaryBtn} id='btnReadMoreSoCBan' to={`/Spectrum-of-Chains/ct/QmFua2ExOTY0LTE=`}
                        style={{
                            backgroundColor: GlobalTheme.palette.common.white,
                            borderRadius: 60,
                            color: GlobalTheme.palette.primary.main,
                            fontWeight: 400,
                            textDecoration: 'none',
                            py: 1.875,
                            px: 3.125,
                        }}>
                        View All
                    </Link>
                </Box>
            </Container>

            {
                slidesData.map((slide, index) => (
                    <Box
                        // className="wow animate__animated animate__fadeIn"
                        // data-wow-duration="1s"
                        // data-wow-delay="1s"
                        // data-wow-offset='100'
                        // data-wow-iteration='1'
                        >
                        <Parallax id={`slide${index}`} key={index}
                            blur={{ min: -15, max: 25 }}
                            bgImage={`${baseBImageUrl}/${slide.image_valley_home_page_banner}`} strength={100} style={{
                                height: 361
                            }}>

                            <Stack display='flex' justifyContent='center' alignItems='center' textAlign='center'
                                sx={{
                                    height: 361,
                                    minHeight: '100%', // Ensures it fills the Parallax height
                                    flexGrow: 1,
                                    px: { xs: 6, lg: 'unset' }
                                }}>
                                <Typography id={`vo${index}`} component='h2' variant='h2' textAlign='center' color={GlobalTheme.palette.common.white} marginBottom={3.5}>{slide.home_banner_text}</Typography>

                                <Link component={bAlink} id={`voLiBa${index}`} to={`/Valley-of-ornaments/${encodeBase64("Banka1964-"+"2")}`} underline="none" className={HomeStyle['slide-link']}>
                                    <Typography component='span' variant='subtitle1' fontWeight={400} color='common.white' className={HomeStyle['link-text']}>View</Typography>
                                </Link>

                            </Stack>

                        </Parallax>
                    </Box>
                ))
            }

            < MarqueeScroll />
            <Footer />
        </>
    )
}

export default Home;