import React, { useState, useEffect, forwardRef } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Link as bAlink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Container from '@mui/material/Container';

import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';

import Logo from './assets/images/logo.gif';
import { GlobalTheme } from '../../theme';
import HeaderStyle from './assets/scss/header.module.scss';
import './assets/scss/menu.css';

const Header = forwardRef((props, ref) => {
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));
    const [sticky, setSticky] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [value, setValue] = React.useState(0);
    // State for categories and subcategories
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState({});

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    const handleDropdownToggle = (dropdown) => {
        if (activeDropdown === dropdown) {
            setActiveDropdown(null); // Close if it's already open
        } else {
            setActiveDropdown(dropdown);
            const megaMenuElement = document.getElementById('megMeBa');
            if (megaMenuElement) {
                megaMenuElement.removeAttribute('style');
            }
        }
    };

    const handleMenuItemClick = () => {
        setActiveDropdown(null); // Close the dropdown when an item is clicked
        const megaMenuElement = document.getElementById('megMeBa');
        if (megaMenuElement) {
            megaMenuElement.style.opacity = 0;
        }
        window.scrollTo(0, 0); // Optional: Scroll to the top on navigation
    };

    // Fetch categories and subcategories on component mount
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                // Fetch categories
                const categoryResponse = await axios.get('https://bankacom.inpro1.fcomet.com/backend/table-data/banka_category');
                setCategories(categoryResponse.data.data);

                // Fetch subcategories for each category
                const subCategoryResponse = await axios.get('https://bankacom.inpro1.fcomet.com/backend/table-data/banka_sub_category');
                const subCategoriesByCategory = {};
                subCategoryResponse.data.data
                    .sort((a, b) => a.appear_order - b.appear_order) // Sort by appear_order in ascending order
                    .forEach((subCategory) => {
                        if (!subCategoriesByCategory[subCategory.category_id]) {
                            subCategoriesByCategory[subCategory.category_id] = [];
                        }
                        subCategoriesByCategory[subCategory.category_id].push(subCategory);
                    });
                setSubCategories(subCategoriesByCategory);
                console.info(subCategoriesByCategory);
            } catch (error) {
                console.error('Error fetching categories or subcategories:', error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 992) {
                setMenuOpen(false);
                setActiveDropdown(null); // Close all dropdowns on larger screens
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 500);
        };

        window.addEventListener('scroll', handleScroll); // Pass 'handleScroll' as the second argument

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const encodeBase64 = (id) => {
        return btoa(id.toString());
    };
    return (
        <AppBar id='hePaBa' ref={ref} position={sticky ? 'fixed' : 'relative'}
            sx={{
                pt: 3.25, pb: 1.125,
                backgroundColor: GlobalTheme.palette.common.white,
                boxShadow: sticky ? (theme) =>
                    `0 0 10px 0 rgba(0, 0, 0, 0.1), 0 0 20px 0 ${theme.palette.primary.light}, 0 0 0 0 ${theme.palette.primary.main}` : 'none',
                borderBottom: '1px solid transparent',
                borderImage: `linear-gradient(0.25turn, rgba(255,255,255), rgba(217,159,89), rgba(217,159,89))`,
                borderImageSlice: 1
            }}>
            <Toolbar component={isDesktop ? 'nav' : ''} sx={{ justifyContent: 'space-between' }}>
                <Box className="navbar">
                    <Box className="navbar-inner"
                        sx={{
                            justifyContent: isDesktop ? 'space-between' : 'center'
                        }} >
                        <Link component={bAlink} to="/" >
                            <img src={Logo} alt="Logo Banka" className={HeaderStyle.logo} />
                        </Link>
                    </Box>

                    {isDesktop && (
                        <div className={`navbar-block ${menuOpen ? 'is-active' : ''}`} id="menu">
                            <ul className="menu">
                                <ListItem className="menu-item" sx={{ width: 'auto' }}>
                                    <Link component={bAlink} to="/About-us" className="menu-link hover-ani" underline="none">Origin</Link>
                                </ListItem>
                                <li className="menu-item dropdown">
                                    <Link
                                        component={bAlink}
                                        to='/Collections'
                                        className="text-base dropdown-toggle menu-link hover-ani"
                                        onMouseEnter={() => handleDropdownToggle('collections')}
                                        onClick={handleMenuItemClick}
                                        style={{ color: GlobalTheme.palette.secondary.light, textDecoration: 'none' }}
                                    >
                                        Collections <em className="bx bx-chevron-down"></em>
                                    </Link>
                                    <Box id='megMeBa' className="dropdown-content"
                                    >
                                        {/* {categories.map((category) => (
                                            <Box className="dropdown-column" key={category.id}
                                                sx={{
                                                    py: 5
                                                }}>
                                                <Box className="dropdown-title">
                                                    <Link
                                                        id={`catChaLiBa${index}`}
                                                        component={bAlink}
                                                        to={`/PlpCategory?category_id=${category.id}&page=${category.category}`}
                                                        onClick={handleMenuItemClick}
                                                        target='_self'
                                                        className="text-base"
                                                        fontSize={GlobalTheme.typography.h2.fontSize}
                                                        underline='none' fontWeight={300}>
                                                        {category.category}
                                                    </Link>
                                                </Box>
                                                <List id={`catChaBa${index}`} className="dropdown-items" sx={{ display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                                    {subCategories[category.id]
                                                        ?.filter((subCategory) => subCategory.is_active === 1) // Filter subcategories where is_active is 1
                                                        .map((subCategory) => (
                                                            <ListItem key={subCategory.id} sx={{ justifyContent: 'end', width: '20%' }}>
                                                                <Link
                                                                    id={`subCatBa${subCategory.id}`}
                                                                    component={bAlink}
                                                                    to={`/plp?subcategory_id=${subCategory.id}&page=${subCategory.sub_category}`}
                                                                    className="dropdown-link"
                                                                    underline='none'
                                                                    onClick={handleMenuItemClick}
                                                                    sx={{
                                                                        color: GlobalTheme.palette.secondary.lightest,
                                                                        "&:hover, &:focus": {
                                                                            color: GlobalTheme.palette.secondary.main,
                                                                        }
                                                                    }}>
                                                                    {subCategory.sub_category}
                                                                </Link>
                                                            </ListItem>
                                                        ))}
                                                </List>
                                            </Box>
                                        ))} */}
                                        {categories.map((category) => {
                                            const activeSubCategories = subCategories[category.id]?.filter((subCategory) => subCategory.is_active === 1) || [];
                                            const isOdd = activeSubCategories.length % 2 !== 0;

                                            return (
                                                <Box
                                                    className="dropdown-column"
                                                    key={category.id}
                                                    sx={{
                                                        py: 5,
                                                    }}
                                                >
                                                    <Box className="dropdown-title">
                                                        <Link
                                                            component={bAlink}
                                                            to={`/${category.category}/ct/${encodeBase64("Banka1964-"+category.id)}`}
                                                            onClick={handleMenuItemClick}
                                                            target="_self"
                                                            className="text-base"
                                                            fontSize={GlobalTheme.typography.h2.fontSize}
                                                            underline="none"
                                                            fontWeight={300}
                                                        >
                                                            {category.category}
                                                        </Link>
                                                    </Box>
                                                    <List
                                                        id="catChaBa"
                                                        className="dropdown-items"
                                                        sx={{
                                                            display: 'inline-flex',
                                                            flexWrap: 'wrap',
                                                            justifyContent: isOdd ? 'flex-start' : 'flex-end',
                                                        }}
                                                    >
                                                        {activeSubCategories.map((subCategory) => (
                                                            <ListItem
                                                                key={subCategory.id}
                                                                sx={{ justifyContent: 'end', width: '20%' }}
                                                            >
                                                                <Link
                                                                    id={`subCatBa${subCategory.id}`}
                                                                    component={bAlink}
                                                                    to={`/${category.category}/${subCategory.sub_category.replace(/\s+/g, '-')}/bk/${encodeBase64("Banka1964-"+subCategory.id)}`}
                                                                    className="dropdown-link"
                                                                    underline="none"
                                                                    onClick={handleMenuItemClick}
                                                                    sx={{
                                                                        color: GlobalTheme.palette.secondary.lightest,
                                                                        "&:hover, &:focus": {
                                                                            color: GlobalTheme.palette.secondary.main,
                                                                        },
                                                                    }}
                                                                >
                                                                    {subCategory.sub_category}
                                                                </Link>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Box>
                                            );
                                        })}

                                    </Box>
                                </li>
                                <ListItem className="menu-item" sx={{
                                    width: 'auto'
                                }}>
                                    <Link
                                        component={bAlink}
                                        to="/Connect"
                                        sx={{
                                            backgroundImage: 'linear-gradient(to right, #49AAF0, #0E1D42)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            backgroundSize: '200% auto',
                                            transition: 'background-position 0.5s ease',
                                            textDecoration: 'none',
                                            display: 'inline-block',

                                            '&:hover': {
                                                // backgroundSize: '100% auto',
                                                backgroundPosition: 'right center', // Change the position of the gradient on hover
                                            },
                                        }}
                                    >
                                        Connect
                                    </Link>
                                </ListItem>
                            </ul>
                        </div>
                    )}
                </Box>
            </Toolbar>
        </AppBar >
    );
});

export default Header;
